/* eslint-disable max-statements, complexity */
import React, {Fragment, useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import LoginControl from "./control";
import { mobileNumberValidator } from "../../../utils/validators/mobile-number-validator";
import { NUMBER, USER_LOGIN_MEDIUM, USER_LOGIN_STATUS } from "../../../constants/app-constants";
import { sendCustomGaEvents } from "../../../tracking";
import {
    phoneNumberEntered,
    phoneNumberAttempted,
    whatsappCheckboxClicked,
    otpIncorrect,
    optLoginSuccess,
    ga4Signin
} from "./tracking";
import { clearEcommerce } from "../../../constants/tracking-constants/ga4-ecommerce-event";
import { LOGIN_SOURCE, NETWORK_ERROR_CONSTANTS } from "./constants";

const LoginSignup = ({
    //------callbacks--------
    children,
    isVisible,
    onClose,
    heading,
    onLoginSuccessCallback,
    useCustomOtpHandler = false,
    extraParams = {},
    customPhone = "",
    handleCustomOtpSend = () => {},
    handleCustomOtpVerification = () => {},
    //-----props-------------
    mobile,
    //-----connects-----------
    sendOTPConnect,
    verifyOtpConnect,
    saveUserConsentConnect,
    loginConnect,
    getProfileDetailsConnect,
    source,
    carAppId = "",
    cityInfo,
    saveUserCibilConsentConnect,
    isEditable = true,
    overlayClass,
    isCustomLogin = false
}) => {
    const [custPhone, setCustPhone] = useState(customPhone || mobile || "");
    const [showOtpField, setShowOtpField] = useState(false);
    const [otp, setOtp] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [allowWhatsapp, setAllowWhatsapp] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [showResendOtp, setShowResendOtp] = useState(false);

    const getEventCategory = () => {
        switch (source) {
        case LOGIN_SOURCE.DETAIL:
            return "Buy_used_cars_B2C_Car_Details_Page";
        case LOGIN_SOURCE.HEADER:
            return "Header_Bar";
        case LOGIN_SOURCE.HOME_PAGE:
            return "Buy_used_cars_B2C_Homepage";
        case LOGIN_SOURCE.LISTING:
            return "Buy_used_cars_B2C_Car_Listing_Page";
        case LOGIN_SOURCE.SELLER:
            return "Seller_Side";
        case LOGIN_SOURCE.WISHLIST_HEADER:
            return "Wishlist_header";
        case LOGIN_SOURCE.WISHLIST_CAR_CARD:
            return "Wishlist_car_card";
        case LOGIN_SOURCE.WISHLIST_CAR_DETAIL:
            return "Wishlist_cardetail";
        case LOGIN_SOURCE.DEEPLINK:
            return "Deeplink";
        case LOGIN_SOURCE.LOAN_PAGE:
            return "Loan_page";
        case LOGIN_SOURCE.CF_FORM:
            return "CF_Form";
        case LOGIN_SOURCE.CHECKOUT:
            return "buy_used_cars_b2c_checkout_page";
        default:
            return "Buyer_Others";
        }
    };

    const onCompleteTimer = () => {
        setShowResendOtp(true);
    };
    const phoneRef = useRef();
    const otpRef = useRef();
    const toggleRef = useRef();

    const isNextDisabled = !mobileNumberValidator(custPhone);

    const reset = () => {
        if (showOtpField) {
            setShowOtpField(false);
            setOtp("");
        }
    };

    const onPhoneChange = (e) => {
        const number = e.target.value;
        if (number.length === NUMBER.ONE) {
            sendCustomGaEvents({
                ...phoneNumberAttempted,
                eventCategory: getEventCategory()
            });
        } else if (number.length === NUMBER.TEN) {
            sendCustomGaEvents({
                ...phoneNumberEntered,
                eventCategory: getEventCategory()
            });
        }
        setCustPhone(number);
    };
    const onOtpChange = (e) => {
        const number = e.target.value;
        setOtp(number);
        setErrorMessage("");
    };

    const focusPhone = () => {
        window.setTimeout(() => {
            if (phoneRef && phoneRef.current) {
                phoneRef.current.focus();
            }
        }, NUMBER.THREE_HUNDRED);
    };

    const focusOtp = () => {
        window.setTimeout(() => {
            if (otpRef && otpRef.current) {
                otpRef.current.focus();
            }
        }, NUMBER.THREE_HUNDRED);
    };

    useEffect(() => {
        if (isVisible) {
            reset();
            if (custPhone.length < NUMBER.TEN) {
                focusPhone();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    const sendOtp = (number) => {
        sendOTPConnect(number);
    };

    const onGetOTP = () => {
        setShowOtpField(true);
        if (useCustomOtpHandler) {
            handleCustomOtpSend(custPhone);
        } else {
            sendOtp(custPhone);
        }
        focusOtp();
    };

    const onWhatsappChange = (e) => {
        const { target: { checked } } = e;
        setAllowWhatsapp(checked);
        sendCustomGaEvents({
            ...whatsappCheckboxClicked,
            eventCategory: getEventCategory()
        });
    };

    const resetErrorMessage = () => {
        setErrorMessage("");
    };

    const saveConsent = () => {
        const params = {
            chat: allowWhatsapp
        };
        saveUserConsentConnect(custPhone, params);
    };

    const fetchProfileDetails = (resolve) => {
        if (isCustomLogin) {
            resolve();
            setShowLoader(false);
        } else {
            getProfileDetailsConnect().then(async (profileResponse) => {
                const { data: { data: { user } } } = profileResponse;
                const { user_id: userId = "", token: secureToken} = user || {};
                await saveUserCibilConsentConnect({userId, source: "WebApp", secureToken}).catch(() => "");
                setShowLoader(false);
                resolve(user);
            });
        }
    };

    const handleUserLogin = (inputOtp) => {
        resetErrorMessage();
        setShowLoader(true);
        const params = {
            phone_number: custPhone,
            otp: inputOtp,
            ...(useCustomOtpHandler && extraParams)
        };
        return new Promise((resolve, reject) => {
            const otpVerificationFn = useCustomOtpHandler ? handleCustomOtpVerification(params) : verifyOtpConnect(params);
            otpVerificationFn.then(async (response) => {
                if (response.error) {
                    const { error: { error_message: errormessage } } = response;
                    setErrorMessage(errormessage);
                    sendCustomGaEvents({
                        ...otpIncorrect,
                        eventCategory: getEventCategory()
                    });
                    setShowLoader(false);
                    return;
                }
                saveConsent();
                const responseData = response.data || {};
                const loginUrl = ((response.data || {}).login_url || "");
                const accessToken = loginUrl.split("access-token=")[1] || responseData["access-token"];
                const payload = {
                    "access-token": accessToken,
                    redirect_url: encodeURIComponent(window.location.href)
                };

                loginConnect(payload).then(() => {
                    fetchProfileDetails(resolve);
                }).catch(() => {
                    fetchProfileDetails(resolve);

                });

            }).catch(error => {
                const errorCode = (error &&
                    error.errors &&
                    error.errors[0] &&
                    error.errors[0].error) ||
                    (error && error.message) || "";
                setErrorMessage((errorCode || "").toLowerCase() === NETWORK_ERROR_CONSTANTS.NETWORK_ERROR ? NETWORK_ERROR_CONSTANTS.INTERNET_ISSUE : errorCode);
                reject(error);
                setShowLoader(false);
            });
        });
    };

    const onSubmitUserDetails = (inputOtp) => {
        handleUserLogin(inputOtp).then((response) => {
            const { phone_number: phoneNumber } = response || {};
            onLoginSuccessCallback(response);
            sendCustomGaEvents({
                ...optLoginSuccess,
                eventCategory: getEventCategory()
            });
            sendCustomGaEvents(clearEcommerce);
            sendCustomGaEvents({
                ...ga4Signin,
                user_id: phoneNumber,
                user_status: USER_LOGIN_STATUS.LOGGED_IN,
                login_type: USER_LOGIN_MEDIUM.PHONE
            });
        });
    };

    const onOtpVerify = () => {
        onSubmitUserDetails(otp);
    };

    return (
        <Fragment>
            {children}
            {isVisible && <LoginControl
                heading={heading}
                onClose={onClose}
                showOtpField={showOtpField}
                custPhone={custPhone}
                otpRef={otpRef}
                otp={otp}
                phoneRef={phoneRef}
                toggleRef={toggleRef}
                isNextDisabled={isNextDisabled && !customPhone}
                showLoader={showLoader}
                onPhoneChange={onPhoneChange}
                onOtpChange={onOtpChange}
                onGetOTP={onGetOTP}
                allowWhatsapp={allowWhatsapp}
                errorMessage={errorMessage}
                onWhatsappChange={onWhatsappChange}
                onOtpVerify={onOtpVerify}
                showResendOtp={showResendOtp}
                onCompleteTimer={onCompleteTimer}
                setShowResendOtp={setShowResendOtp}
                setShowOtpField={setShowOtpField}
                getEventCategory={getEventCategory}
                isOTPInvalid={!!errorMessage}
                setErrorMessage={setErrorMessage}
                carAppId={carAppId}
                cityInfo={cityInfo}
                isEditable={isEditable}
                overlayClass={overlayClass}
            />}
        </Fragment>
    );
};

LoginSignup.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    heading: PropTypes.string,
    onLoginSuccessCallback: PropTypes.func,
    mobile: PropTypes.string,
    sendOTPConnect: PropTypes.func,
    verifyOtpConnect: PropTypes.func,
    saveUserConsentConnect: PropTypes.func,
    loginConnect: PropTypes.func,
    getProfileDetailsConnect: PropTypes.func,
    source: PropTypes.string,
    carAppId: PropTypes.string,
    cityInfo: PropTypes.object,
    saveUserCibilConsentConnect: PropTypes.func,
    useCustomOtpHandler: PropTypes.bool,
    extraParams: PropTypes.object,
    handleCustomOtpSend: PropTypes.func,
    handleCustomOtpVerification: PropTypes.func,
    customPhone: PropTypes.string,
    isEditable: PropTypes.bool,
    overlayClass: PropTypes.string,
    isCustomLogin: false
};

export default LoginSignup;
