import React from "react";
import { SUPER_APP_SECTION_TYPES } from "../../../constants/super-app-constants";

import SuperWebLandingBanner from "../super-web-landing-banner";
import SuperWebServicesSection from "../super-web-services-section";
// import SuperWebDiscountSection from "../super-web-discount-section";
import SuperWebGetAppBannerWrapper from "../super-web-get-app-banner-wrapper";
// import SuperWebOurServicesWrapper from "../super-web-our-services-wrapper";
import SuperWebBuyCarWrapper from "../super-web-buy-car-wrapper";
import SuperWebCategoryWrapper from "../super-web-category-wrapper";
import SuperWebWhyUsWrapper from "../super-web-why-us-wrapper";
import SuperWebVideoTestimonialWrapper from "../super-web-video-testimonial-wrapper";
import SuperWebNewsWrapper from "../super-web-news-wrapper";
import SuperWebDownloadAppWrapper from "../super-web-download-app-wrapper";
import SuperWebReviewWrapper from "../super-web-review-wrapper";
import SuperWebNeedHelpWrapper from "../super-web-need-help-wrapper";

export const SUPER_WEB_PAGE_LAYOUT = {
    [SUPER_APP_SECTION_TYPES.SUPER_WEB_HOME_PAGE_HEADER]: SuperWebLandingBanner,
    [SUPER_APP_SECTION_TYPES.SUPER_WEB_SERVICES]: SuperWebServicesSection,
    // [SUPER_APP_SECTION_TYPES.SUPER_WEB_DISCOUNT_SECTION]: SuperWebDiscountSection,
    [SUPER_APP_SECTION_TYPES.SUPER_WEB_GET_APP_SECTION]: SuperWebGetAppBannerWrapper,
    [SUPER_APP_SECTION_TYPES.SUPER_WEB_BUY_CAR_SECTION]: SuperWebBuyCarWrapper,
    // [SUPER_APP_SECTION_TYPES.SUPER_WEB_OUR_SERVICES_SECTION]: SuperWebOurServicesWrapper,
    [SUPER_APP_SECTION_TYPES.SUPER_WEB_WHY_BUY_US_SECTION]: SuperWebWhyUsWrapper,
    [SUPER_APP_SECTION_TYPES.SUPER_WEB_CARS_BY_CATEGORY]: SuperWebCategoryWrapper,
    [SUPER_APP_SECTION_TYPES.SUPER_WEB_HAPPY_STORY_SECTION]: SuperWebVideoTestimonialWrapper,
    [SUPER_APP_SECTION_TYPES.SUPER_WEB_NEWS_SECTION]: SuperWebNewsWrapper,
    [SUPER_APP_SECTION_TYPES.SUPER_WEB_DOWNLOAD_APP_SECTION]: SuperWebDownloadAppWrapper,
    [SUPER_APP_SECTION_TYPES.SUPER_WEB_REVIEW_SECTION]: SuperWebReviewWrapper,
    [SUPER_APP_SECTION_TYPES.SUPER_WEB_NEED_HELP_SECTION]: SuperWebNeedHelpWrapper
};
