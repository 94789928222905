/* eslint-disable max-len */
import { transformKeys } from "../utils/helpers/transform-object-keys";
import { appUrl, currentEnv, staticFastlyAppUrl } from "./url-constants";

export const NUMBER = {
    ZERO: 0,
    DECIMAL_FIVE: 0.5,
    DECIMAL_ZERO_FIVE: 0.05,
    DECIMAL_EIGHT: 0.8,
    DECIMAL_ONE: 0.1,
    DECIMAL_ZERO_EIGHTEEN: 0.18,
    DECIMAL_ONE_FIVE: 1.5,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
    TEN: 10,
    ELEVEN: 11,
    TWELVE: 12,
    THIRTEEN: 13,
    FOURTEEN: 14,
    FIFTEEN: 15,
    SIXTEEN: 16,
    SEVENTEEN: 17,
    EIGHTEEN: 18,
    NINETEEN: 19,
    TWENTY: 20,
    TWENTY_TWO: 22,
    TWENTY_THREE: 23,
    TWENTY_FOUR: 24,
    TWENTY_FIVE: 25,
    TWENTY_SEVEN: 27,
    TWENTY_EIGHT: 28,
    TWENTY_NINE: 29,
    THIRTY: 30,
    THIRTY_FOUR: 34,
    THIRTY_FIVE: 35,
    THIRTY_NINE: 39,
    FOURTY: 40,
    FOURTY_THREE: 43,
    FOURTY_FIVE: 45,
    FOURTY_SEVEN: 47,
    FIFTY: 50,
    FIFTY_FIVE: 55,
    FIFTY_SIX: 56,
    SIXTY: 60,
    EIGHTY: 80,
    SEVENTY: 70,
    NINETY: 90,
    NINETY_FIVE: 95,
    NINETY_EIGHT: 98,
    HUNDRED: 100,
    ONE_HUNDRED_FIVE: 105,
    ONE_HUNDRED_TWENTY: 120,
    TWO_HUNDRED: 200,
    ONE_HUNDRED_EIGHTY: 180,
    TWO_HUNDRED_SEVENTY: 270,
    TWO_HUNDRED_EIGHTY: 280,
    THREE_HUNDRED: 300,
    THREE_HUNDRED_SIXTY: 360,
    FOUR_HUNDRED: 400,
    SIX_HUNDRED: 600,
    SEVEN_HUNDRED: 600,
    FIVE_HUNDRED: 500,
    NINE_HUNDRED: 900,
    THOUSAND: 1000,
    ONE_THOUSAND_TWENTY_FOUR: 1024,
    THIRTEEN_HUNDRED: 1300,
    TWO_THOUSAND: 2000,
    THREE_THOUSAND: 3000,
    FOUR_THOUSAND: 4000,
    FIVE_THOUSAND: 5000,
    SIX_THOUSAND: 6000,
    SEVEN_THOUSAND: 7000,
    EIGHT_THOUSAND: 7000,
    NINE_THOUSAND_NINE_HUNDRED_NINETY_NINE: 9999,
    TEN_THOUSAND: 10000,
    FIFTEEN_THOUSAND: 15000,
    TWENTY_FIVE_THOUSAND: 25000,
    FIFTY_THOUSAND: 50000,
    ONE_LAKH: 100000,
    TWO_LAKH: 200000,
    FIVE_LAKH: 500000,
    TEN_LAKH: 1000000,
    THIRTY_LAKH: 3000000,
    NINETY_NINE_LAKH: 9900000,
    TWENTY_ONE: 21,
    TWENTY_SIX: 26,
    THIRTY_ONE: 31,
    THIRTY_SIX: 36,
    FOURTY_ONE: 41,
    FOURTY_SIX: 46,
    NINETEEN_SEVENTY: 1970,
    MINUS_TWENTY: -20,
    MINUS_FIFTY: -50,
    MINUS_HUNDRED: -100,
    MINUS_NINETY: -90,
    TWO_THOUSAND_FIFTEEN: 2015,
    TWO_THOUSAND_NINE: 2009,
    THIRTY_TWO: 32,
    EIGHTY_SIX: 86,
    SEVENTY_TWO: 72,
    SEVENTY_THREE: 73,
    FIFTY_TWO: 52,
    FIFTY_EIGHT: 58,
    SIXTY_EIGHT: 68,
    SEVENTY_EIGHT: 78,
    SEVENTY_FIVE: 75,
    EIGHTY_FIVE: 85,
    NINETY_TWO: 92,
    MINUS_FIFTEEN: -15,
    MINUS_FIVE: -5,
    SEVENTY_THOUSAND: 70000,
    TWELVE_THOUSAND: 12000,
    ONE_HUNDRED_THIRTY: 130,
    THIRTY_THOUSAND: 30000,
    TWENTY_FIVE_HUNDRED: 2500,
    ONE_HUNDRED_TWENTY_THREE: 123,
    THREE_POINT_SIX: 3.6,
    ONE_HUNDRED_SIX: 106,
    TWO_HUNDRED_FORTY_FIVE: 245,
    TWO_HUNDRED_FIFTY_FOUR: 254,
    FOURTY_EIGHT: 48,
    ONE_HUNDRED_FOUR: 104,
    SIXTY_SIX: 66,
    FOURTY_TWO: 42,
    ONE_POINT_THREE: 1.3,
    THREE_HUNDRED_SEVENTY: 370,
    SEVEN_HUNDRED_SIXTY_EIGHT: 768,
    FOUR_HUNDRED_SEVENTY: 470,
    TWENTY_SIX_HUNDRED: 2600,
    FOURTY_FOUR: 44,
    ONE_HUNDRED_FORTY: 140,
    THREE_SIXTY_FIVE: 365,
    ONE_POINT_EIGHT: 1.8,
    ONE_POINT_TWO: 1.2,
    TWO_POINT_FOUR: 2.4,
    ONE_POINT_FOUR: 1.4,
    THREE_POINT_TWO: 3.2,
    TWO_POINT_EIGHT: 2.8,
    ONE_THOUSAND_FIVE_HUNDRED: 1500
};

export const WINDOW_SIZE_LIMIT = 1600;

export const SIMILAR_CAR_THRESHOLD_COUNT = 40;

//TODO
export const seoTemplateType = {
    MAKE: "make",
    CITY: "city",
    MAKE_CITY: "makeCity",
    MAKE_CITY_LOCATION: "makeCityLocation",
    MAKE_MODEL: "makeModel",
    MAKE_MODEL_CITY: "makeModelCity",
    MAKE_MODEL_CITY_LOCATION: "makeModelCityLocation",
    CITY_LOCATION: "cityLocation",
    GENERIC: "generic"
};

export const stringConstants = {
    YES: "yes",
    NO: "no"
};

//added undefiend for local env - has to tbe removed
const baseUrl = {
    privateProduct: {
        QA: "https://c2b-website.qac24svc.dev/account",
        STAGING: "https://stage-c2b-website.qac24svc.dev/account",
        LNOD: "https://stage-consumer-private-web.qac24svc.dev/account",
        PRODUCTION: "https://www.cars24.com/account"
    },
    c2c: {
        QA: "https://c2b-website-poc.qac24svc.dev/buy-used-cars",
        STAGING: "https://stage-c2b-website.qac24svc.dev/buy-used-cars",
        // STAGING: "http://localhost:3000//buy-used-cars",
        LNOD: "https://stage-c2b-website.qac24svc.dev/buy-used-cars",
        PRODUCTION: "https://www.cars24.com/buy-used-cars"
    },
    consumerWebIn: {
        QA: "https://consumer-web-in.qac24svc.dev",
        STAGING: "https://stage-consumer-web-in.qac24svc.dev",
        LNOD: "https://stage-consumer-web-in.qac24svc.dev",
        PRODUCTION: "https://www.cars24.com"
    }
};

export const redirect = {
    AUTH_LOGIN: `${baseUrl.privateProduct[currentEnv]}/login/`,
    AUTH_APPOINTMENTS: `${baseUrl.privateProduct[currentEnv]}/appointments/`,
    AUTH_BIDS: `${baseUrl.privateProduct[currentEnv]}/bids/`,
    AUTH_PROFILE: `${baseUrl.privateProduct[currentEnv]}/profile/`,
    c2C: (path = "") => `${baseUrl.c2c[currentEnv]}${path}`,
    CARS24_HOME: appUrl,
    BYE_BYE_DRIVE: `${appUrl()}/byebyedrive/`,
    BLOG: `${appUrl()}/blog/`,
    RC_TRANSFER: `${appUrl()}/rc-transfer/`,
    HOW_IT_WORKS: `${appUrl()}/how-it-works/`,
    WE_ARE_HIRING: "https://cars24.recruitee.com/",
    FAQ: `${appUrl()}/faq/`,
    FAQ_AE: `${appUrl()}/ae/faq/`,
    HELP: `${appUrl()}/need-help/`,
    BECOME_OUR_PARTNER: `${appUrl()}/become-our-partner/`,
    BRANCHES: `${appUrl()}/centres/`,
    CAR_LOAN: `${appUrl()}/car-loan/`,
    TESTIMONIALS: `${appUrl()}/testimonials/`,
    GOOGLE_PLAY_STORE_APP: "https://play.google.com/store/apps/details?id=com.cars24.seller&hl=en_IN",
    APPLE_STORE_APP: "https://itunes.apple.com/in/app/cars24-sell-car-at-best-price/id1364492085?mt=8",
    FINANCIAL_SERVICES_TERMS_CONDITIONS: "https://img.24c.in/cars24/docs/finance/terms_&_conditions.pdf",
    MY_BOOKINGS: `/my-bookings/`,
    CARS24_Loan: `${appUrl()}/used-car-loan/`,
    NEW_CARS: `${appUrl()}/new-cars/`,
    BUY_HOME: `${appUrl()}/buy-used-cars/`,
    SELL_HOME: `${appUrl()}/sell-used-cars/`,
    CHALLAN_HOMEPAGE: `${appUrl()}/traffic-challan/`,
    SERVICE_HISTORY: `${appUrl()}/car-service-history/`,
    MY_ORDERS: `${appUrl()}/my-orders/`,
    FOURDOOR: `${appUrl()}/fourdoor/`,
    RTO: `${appUrl()}/rto-vehicle-registration-details/`,
    SCRAP_CAR: `${appUrl()}/scrap-cars/`,
    EXPLORE_NEW_CARS: `${appUrl()}/new-cars/`,
    FRANCHISE: `${appUrl()}/franchise/`,
    CONSUMER_WEB_IN: `${baseUrl.consumerWebIn[currentEnv]}`,
    COMMUNICATION_PREFERENCES: `${appUrl()}/communication-preferences/`
};

export const appRedirect = {
    HOME: "/",
    BLOG: "/blog",
    RC_TRANSFER: "/rc-transfer",
    HOW_IT_WORKS: "/how-it-works",
    WE_ARE_HIRING: "https://cars24.recruitee.com/",
    FAQ: "/faq",
    HELP: "/need-help",
    BECOME_OUR_PARTNER: "/become-our-partner",
    BRANCHES: "/centres",
    SELLCAR: "/sell-car",
    SELLOLDCAR: "/sell-old-car",
    CASHFORCAR: "/cash-for-car",
    USEDCAR: "/used-car",
    HOME3: "/home-3",
    SELLUSEDCAR: "/sell-used-car"
};

export const CITY_KEY = "selectedCity";

export const MISSED_CALL_CHECK_INTERVAL = 30000;

//TODO
export const STEP_NAMES = {
    Brand: "brand",
    Model: "model",
    FuelType: "fuelType",
    Transmission: "transmission",
    Year: "year",
    Variant: "variant",
    State: "registrationState",
    Confirmation: "confirmation",
    KilometerDriven: "kilometersDriven",
    Branch: "branch",
    Date: "date",
    Time: "time",
    Override: "override",
    User: "user",
    Location: "location",
    Price: "price",
    Body: "body",
    Priority: "priority",
    CarService: "carService",
    Personal: "personal",
    Residential: "residential",
    Income: "income",
    RTO: "rto",
    Kms: "kms",
    City: "city"
};
//TODO
export const CURRENT_REG_SCREEN = {
    STATE: "state",
    RTO: "rto"
};
export const OTP_MAX_LENGTH = 4;

export const HTTP_CODES = {
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    SUCCESS: 200,
    FOUND: 302,
    MOVED_PERMANENTLY: 301,
    BAD_REQUEST: 400,
    PAGE_NOT_FOUND: 410,
    RESOURCE_ALREADY_EXIST: 409,
    PRECONDITION_FAILED: 412,
    UNPROCESSABLE: 422
};

export const LISTING_PAGE_NOT_FOUND_V2 = "CAT410";
export const DETAILS_PAGE_NOT_FOUND = "CAT401";

export const LEAD_STATUSES = {
    CREATED: "Created",
    CAPTURED: "AppointmentCaptured"
};

export const APPOINTMENT_BRANCH_STORE_TYPES = {
    STORE: "store"
};

export const BRANCH_LIST_RIGHT_OFFSET = 275;

export const DEFAULT_SLIDER_SETTINGS = {
    slidesToShow: 3.5,
    slidesToScroll: 1,
    infinite: false,
    arrow: true
};

export const BRANCH_STORE_TYPES = [
    "franchise",
    "store"
];

export const LOCAL_STORAGE_KEYS = {
    AB_LEAD_SOURCE: "ab_lead_source",
    DRS_LANDING_PAGE: "DRS_LP",
    ALTERNATE_LEAD_FLOW: "c2b_alt_lead",
    AB_COVID: "covid_lp"
};

export const DEFAULT_MOBILE_SLIDER_SETTINGS = {
    slidesToShow: 1.14,
    slidesToScroll: 1,
    infinite: false,
    arrow: false
};

export const ACCEPTED_IMAGE_TYPES = ["image/png", "image/jpeg"];

//todo
export const USED_CAR_ALLOWED_CITIES = [];

export const COOKIE_SEARCH_KEYWORDS = "c2b-searched-keywords";

export const DS_TYPES = {
    OBJECT: "object"
};

export const CITY_NOT_LISTED = {
    background_image: null,
    background_image_mobile: null,
    city_name: "My city is not listed here"
};

export const ALTERNATE_LEAD_CITIES = ["769", "648", "650"];

//todo
export const LEAD_FORM = {
    DEFAULT: "default",
    ONE_STEP: "oneStep",
    CARFINDER: "carfinder",
    FINANCE_FORM: "financeForm",
    DEFAULT_REVAMP: "defaultRevamp",
    APPOINTMENT: "appointment"
};

export const COLUMN_MAPPING = {
    1: "col-12",
    2: "col-6",
    3: "col-4",
    4: "col-3",
    6: "col-2",
    12: "col-1"
};

export const LOCATION_REQUEST_TYPE = {
    COORDINATES: "coordinates",
    PLACE: "place"
};

export const MAP_TYPE = {
    GOOGLE: "googlemap",
    MMI: "mapmyindia",
    OLA: "olamaps"
};

export const ACKO_CAMPAIGN_CITIES = ["2", "132", "6", "5", "134", "2378"];

export const DISPOSITION_STATUS = {
    SOLD: "SOLD",
    PUBLISHED: "PUBLISHED",
    BOOKED: "BOOKED",
    LOST: "LOST",
    RE_PUBLISH: "RE-PUBLISH",
    RE_PUBLISH_1: "RE_PUBLISH",
    AUCTIONED: "AUCTIONED",
    REJECTED: "REJECTED",
    DRAFT: "DRAFT",
    RESERVED: "RESERVED",
    SERVICING: "SERVICING",
    EXPIRED: "EXPIRED"
};

export const CAR_DISPOSITION_STATUS = {
    AVAILABLE: "AVAILABLE",
    COMING_SOON: "COMING_SOON",
    SERVICING: "SERVICING",
    SOLD: "SOLD",
    RESERVED: "RESERVED"
};

export const DISPOSITION_STATUS_MAP = {
    [CAR_DISPOSITION_STATUS.SERVICING]: DISPOSITION_STATUS.DRAFT,
    [CAR_DISPOSITION_STATUS.SOLD]: DISPOSITION_STATUS.SOLD,
    [CAR_DISPOSITION_STATUS.AVAILABLE]: DISPOSITION_STATUS.PUBLISHED
};

export const DEFAULT_C2C_CITY = {
    city_id: "2",
    city_name: "New Delhi",
    city_slug: "new-delhi",
    pincode: "110010"
};

export const VIRUTAL_CITY = {
    city_id: DEFAULT_C2C_CITY.city_id,
    city_name: DEFAULT_C2C_CITY.city_name,
    city_slug: DEFAULT_C2C_CITY.city_slug,
    isReplicaOfCity: "2"
};

export const AD_TYPE = {
    // INSTANTLY_AVAILABLE: "instantlyAvailable",
    // MONEYBACK_GUARANTEE: "moneybackGuarantee",
    // GET_CAR_PRICE: "getCarPrice",
    EXCHANGE_OFFER: "exchangeOffer",
    DISCOUNT_BANNER: "diascountBanner",
    EMI_DISCOUNT: "emiOffers",
    FINANCE_AVAILABLE: "financeAvailable",
    WARRANTY_AVAILABLE: "warrantyAvailable",
    MRL_CERTIFIED: "mrlCertified",
    CHECK_EMI: "checkEMI"
    // PRICE_DROP_AVAILABLE: "priceDropAvailable"
    // VIEW_360_AVAILABLE: "view360Available"
    // CAR_FINDER: "carFinder",
};

export const MIN_LOAN_AMOUNT = 50000;

export const CAR_LIST_EVENT_NAMES = {
    LISTING: "Buy_used_cars_C2C_Car_Listing_Page",
    B2C_LISTING: "Buy_used_cars_B2C_Car_Listing_Page",
    MONEYBACK: "Buy_used_cars_C2C_Car_Listing_Page_Moneyback",
    INSTANTLY_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_Instantly_Available",
    FINANCE_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_Finance_Available",
    PRICE_DROP: "Buy_used_cars_C2C_Car_Listing_Page_Price_Drop",
    HOME_PAGE: "Buy_used_cars_C2C_Car_Home_Page",
    B2C_HOME_PAGE: "Buy_used_cars_B2C_Homepage",
    FILTER_PAGE: "Buy_used_cars_C2C_filter_page",
    DETAILS: "Buy_used_cars_C2C_Car_Details_Page",
    VIEW_360_AVAILABLE: "Buy_used_cars_C2C_Car_Listing_Page_View_360_Available"
};

export const SCRIPT_TYPES = {
    mmi: "mmi",
    googlemap: "googlemap"
};

export const AB_TEST_VARIATIONS = {
    CONTROL: "CONTROL",
    VARIANT: "VARIANT",
    VARIANT_A: "VARIANT_A",
    VARIANT_B: "VARIANT_B",
    VARIANT_C: "VARIANT_C",
    VARIANT_D: "VARIANT_D",
    VARIANT_E: "VARIANT_E",
    VARIANT_F: "VARIANT_F",
    CONTROL_MUMBAI: "CONTROL_MUMBAI",
    VARIANT_MUMBAI: "VARIANT_MUMBAI",
    PAID: "PAID",
    FREE: "FREE"
};

export const HIDE_POPUP_FOR_URLS = [
    /([a-z]*)(confirmation)([a-z]*)/gi,
    /([a-z]*)(customer-review)([a-z]*)/gi,
    /(\/mobile-apps)/gi,
    /(offer|car-prices|specifications|images|variants|reviews|colors|mileage|service-cost|faqs|rto-vehicle-registration-details|-prices-in-|car-finder)/gi,
    /(mycar)([a-z]*)/gi,
    /(buyer-details)([a-z]*)/gi,
    /(seller-details)([a-z]*)/gi,
    /tokenpayment([a-z]*)/gi,
    /cars24-select/gi,
    /(buy-used-cars\/)/gi,
    /(buy-used-cars)$/,
    /(checkout)([a-z]*)/gi,
    /([a-z]*)(traffic-challan)([a-z]*)/gi,
    /(my-challans)([a-z]*)/gi,
    /(car-service-history)([a-z]*)/gi,
    /(car-service-history-info)([a-z]*)/gi,
    /(order-summary)([a-z]*)/gi
];

export const PATHS = [
    /(\/centres)/gi,
    /(\/mobile-apps)/gi,
    /(\/customer-review)/gi,
    /(\/faq)/gi,
    ...HIDE_POPUP_FOR_URLS
];

export const CTA_LOCATIONS = {
    LIST_SELL_CAR: "classified",
    INSTANT_SELL_CAR: "instant_sell_car",
    REFINANCE: "REFINANCE"
};

export const PRICES = {
    GREAT: "great",
    GOOD: "good",
    FAIR: "fair",
    OVER_PRICED: "overpriced"
};

export const APPOINTMENT_TYPE = {
    BRANCH: "branch",
    HOME: "inspection",
    CRM: "CRM"
};

export const APPOINTMENT_SCREEN = {
    DEFAULT: "default",
    ADDRESS: "address",
    DOCUMENT: "document",
    BOOKING: "booking",
    SLOT: "slot"
};

export const FORM_TYPE = {
    CAR: "car",
    CLASSIFIED_RESCHEDULE: "classified_reschedule",
    ONE_STEP: "one_step",
    PAYWALL_BOOK_NOW: "paywall_book_now",
    PAYWALL_MAKE_OFFER: "paywall_make_offer",
    GS_BOOK_NOW: "gs_book_now",
    TOKEN_PAYMENT: "token_payment",
    VONE_STEP: "vone_step",
    CHECKOUT: "checkout",
    IN_APP_PAYMENT: "in_app_payment",
    C2B_VAS: "c2b_vas",
    CAR_WASH: "car_wash",
    CAR_HEALTH: "car_health",
    PRE_DELIVERY_INSPECTION: "pre_delivery_inspection"
};
// eslint-disable-next-line no-magic-numbers
export const MAX_ALLOWED_UPLOAD_SIZE = 5 * 1024 * 1000;

export const APPOINTMENT_LOCATION_TYPE = {
    LOCALITY: "locality"
};

export const LOC_ID = {
    HOME: 3,
    BRANCH: 1,
    CLASSIFIED: 4,
    SELL_ONLINE: 5
};

export const VERIFIED_SOURCE = {
    PAYMENT: "payment"
};

//todo
export const CUSTOM_ACTION_TYPES = {
    SET_LOCATION_NAME: "SET_LOCATION_NAME",
    SET_WHATSAPP_CONSENT: "SET_WHATSAPP_CONSENT",
    SET_ACTIVE_SCREEN: "SET_ACTIVE_SCREEN",
    LEAD_ACTION: "LEAD_ACTION",
    SET_DATA: "SET_DATA",
    SET_VISITED_STATE: "SET_VISITED_STATE",
    OVERRIDE_CURRENT_POSITION_KEYS: "OVERRIDE_CURRENT_POSITION_KEYS",
    SHOW_PROCEED_BUTTON: "SHOW_PROCEED_BUTTON",
    SET_STYLES: "SET_STYLES"
};

export const IMAGE_UPLOADER_ENUMS = {
    UPLOAD: "upload",
    DELETE: "delete",
    VALIDATION_FAILURE: "validationFailure"
};

export const SELLER_PORTAL_SCREEN = {
    HOME: "HOME",
    DELIST: "DELIST",
    REVISE: "REVISE"
};

export const FALLBACK_C2B_CITY_ID = "2";

export const LEAD_TYPES = {
    REFINANCE: "refinance",
    EXCHANGE: "EXCHANGE"
};

export const CAR_SOURCE = {
    GS: "GS_CAR"
};

export const COOKIE_KEYS = {
    LAST_CLICK_ATTRIBUTE: "last_click_attribute",
    FIRST_CLICK_ATTRIBUTE: "first_click_attribute"
};

export const INSPECTION_VIEW_MORE_AFTER = 6;

export const CITY_ID = {
    MUMBAI: "2378",
    BENGALURU: "4709"
};

export const BOOK_NOW_CITIES = [];
export const MAKE_OFFER_CITIES = [CITY_ID.BENGALURU];

export const WHATSAPP_STAGE = {
    STAGE_1: "stage_1",
    STAGE_2: "stage_2",
    STAGE_2_APP: "STAGE2",
    STAGE_1_APP: "STAGE1",
    NO: "no"
};

export const PAYMENT_OPTIONS = {
    FINANCE: "FINANCE",
    CASH: "CASH"
};

export const ANGLE = {
    ZERO: 0,
    NINETY: 90,
    HUNDRED_EIGHTY: 180,
    TWO_HUNDRED_SEVENTY: 270,
    THREE_HUNDRED_SIXTY: 360,
    MINUS_HUNDRED_EIGHTY: -180
};

export const CURRENCY = {
    IN: "₹"
};

export const COUNTRY_CODE = {
    IN: "IN"
};

export const LOCATION_TAB = {
    MANUAL: "manual",
    CURRENT: "current"
};

export const REPORT_TYPE = {
    OVERVIEW: "OVERVIEW",
    FEATURE: "FEATURE",
    PERFORMANCE: "PERFORMANCE"
};
// TODO
export const ACTIVE_VARIANT_SCREEN = {
    FUELTYPE: "fueltype",
    TRANSMISSIONTYPE: "transmissionType",
    VARIANT: "variant",
    VARIANT_TYPE: "variantType"
};

export const USER_CHOICE = {
    OFFER_ACCEPTED: "accepted_offer",
    NEED_SOME_TIME: "need_some_time",
    NOT_INTERESTED: "not_interested"
};

export const SELL_ONLINE_RESCHEDULE_PAGE = {
    SELL_ONLINE_PATH: "/sell-online-offer",
    ADDRESS: "address",
    SLOT: "slot",
    REGISTRATION: "registration",
    BOOKED: "booked",
    RESCHEDULE: "/reschedule-appointment"
};

export const DISCOUNT_END_DATE = "2021-11-30";

export const SELL_ONLINE_USER_CHOICE = {
    SELL_LATER: "sell_later",
    SELL_NOW: "sell_now"
};
//todo
export const SCREEN_TYPES = {
    SELL_ONLINE_DETAIL: "SODetail",
    SELL_ONLINE_LOCATION: "SOLocation"
};

export const LOCATION_TYPE = {
    CURRENT: "current",
    CITY: "city"
};

export const DROP_OFF_PAGE = {
    OFFER_PAGE: "offerPage",
    BOOK_APPOINTMENT: "bookAppointment",
    RESCHEDULED: "rescheduled"
};

export const DISCOUNT_BANNER_PAGE = {
    LISTING: "listing",
    SEO: "seo"
};

export const DEFAULT_SOURCE_TYPE = { //for time being phone is set as default after BE implementation change to Email
    key: "phone",
    displayText: "Phone"
};

export const SOURCE_TYPES = [
    {
        key: "email",
        displayText: "Email"
    },
    {
        key: "phone",
        displayText: "Phone"
    }
];

export const DSA_STEPS_KEY = {
    SELLER_DETAILS: "sellerdetails",
    CAR_DETAILS: "cardetails",
    CAR_CONDITION: "carcondition",
    DOCUMENT_DETAIL: "documentdetails",
    APPOINTMENT: "appointment"
};

export const DSA_FLOW_TYPES = {
    SELL_ONLINE: "SELL_ONLINE_DSA"
};

export const downloadAutonautURL = {
    AUTONAUT: "https://cars24.app.link/kn3H2rXj6Ob"
};

export const DEVICE_TYPE = {
    ANDROID: "Android",
    IOS: "iOS"
};

export const downloadLayoverURL = {
    ANDROID: "https://cars24.app.link/izv2ifzlWjb",
    IOS: "https://cars24.app.link/WJk4poFmWjb"
};

export const downloadIconURL = {
    ANDROID: "https://cars24.app.link/EyyV36VmWjb",
    IOS: "https://cars24.app.link/vQISQM5mWjb",
    TRUST_PILOT: "https://www.trustpilot.com/review/cars24.com"

};

export const downloadSeoURL = {
    ANDROID: "https://cars24.app.link/aJbNoImnWjb",
    IOS: "https://cars24.app.link/03jWeErnWjb"
};

export const downloadRtoURL = {
    ANDROID: "https://cars24.app.link/cpcaplBnWjb",
    IOS: "https://cars24.app.link/XOicXjEnWjb"
};

export const downloadAppWay = {
    LAYOVER: "layover",
    ICON: "icon",
    SEO: "seo",
    RTO: "rto"
};

export const COUPONS_LIST = {
    USER_DISCOUNT: "USER_DISCOUNT"
};

export const SCROLL_PROPERTY = {
    BEHAVIOR: "smooth"
};

export const SOURCE_TRUECALLER = "truecaller";

export const APPOINTMENT_BOOKING_ROUTE = ["/sell-online-offer/address", "/sell-online-offer/address/", "/sell-online-offer/booked", "/sell-online-offer/booked/", "/appointment/booking"];
export const OFFER_PRICE_ROUTE = ["/sell-online-offer", "/sell-online-offer/"];
export const APPOINTMENT_BOOKED = ["/sell-online-offer/booked", "/sell-online-offer/booked/"];
export const APPOINTMENT_STATUS = {
    CANCELLED: "CANCELLED",
    INSPECTED: "INSPECTED"
};

export const MODAL_USER_ACTION = {
    SELL: "sell",
    BUY: "buy",
    RESOURCES: "Resources",
    CF: "cf",
    NEWCARS: "NewCars",
    CARSERVICE: "CarService"
};

export const C2C_PAGE_TYPE = {
    HOMEPAGE: "homepage",
    CAR_LISTING: "car_listing",
    CAR_DETAILS: "car_details"
};

export const helloArGaId = () => {
    const gaId = {
        STAGING: "UA-65843992-1-STAGING", //dummy ID {ID} + staging
        QA: "UA-65843992-1-QA", //dummy ID {ID} + QA
        PRODUCTION: "UA-65843992-1"
    };
    return gaId[currentEnv];
};

export const CF_OFFER_TYPE = {
    TOF: "TOF",
    CHECKOUT: "CHECKOUT"
};

export const OFFER_CHANNEL = {
    AMBER_CHANNEL: "AmberChannel"
};

export const AGE_RULE_VALIDATION = 18;

export const alternativeClientId = "GA1.2.990762632.1649855858";

export const TRUECALLER_STATUS = {
    AVAILABLE: "AVAILABLE",
    UNAVAILABLE: "UNAVAILABLE"
};

export const TRUECALLER_USER_STATUS = {
    USER_REJECTED: "user_rejected",
    USE_ANOTHER_NUMBER: "use_another_number"
};

export const PORTRAIT_TEXT = "Portrait";
export const LANDSCAPE_TEXT = "LandScape";
export const EXTERIOR_TEXT = "exterior";
export const INTERIOR_TEXT = "interior";

export const B2C_OMS_VARIANTS = {
    haptikvar: "haptikControlVariant",
    deliveryModeOption: "deliveryVerticalVariant",
    loginFlow: "loginFlow",
    equifax: "equifax",
    equifaxLoader: "equifaxLoader",
    cibilSoftPull: "cibilSoftPull",
    cfFeedback: "cfFeedback",
    addMoreCars: "addMoreCars"
};

export const BASE_URL = "https://fastly-production.24c.in/";

export const GOOGLE_MAP_REDIRECTION_LINK = "https://maps.google.com/?q=";

export const EXCLUDED_UTM_CAMPAIGNS_FOR_SHOWING_LISTING_PAGE_POPUP = ["park+"];

export const panRegex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;

export const PETROLCNGTEXT = "Petrol + CNG";
export const CNGTEXT = "CNG";
export const enabledWolCity = ["Bangalore", "Bengaluru"];

export const CTA_WITHOUT_PINCODE_PAGES = {
    HOME_PAGE_VIEW_ALL_CARS: "HOME_PAGE_VIEW_ALL_CARS",
    HOMEPAGE_SEARCH_PAGE: "HOMEPAGE_SEARCH_PAGE",
    HOMEPAGE_FILTER_PAGE: "HOMEPAGE_FILTER_PAGE",
    HOMEPAGE_DISCOUNT_BANNER: "HOMEPAGE_DISCOUNT_BANNER",
    HOMEPAGE_BRAND_SELECTION: "HOMEPAGE_BRAND_SELECTION",
    BOTTOM_NAV_BAR: "BOTTOM_NAV_BAR",
    HEADER_BUY_USED_CARS: "HEADER_BUY_USED_CARS"
};

export const USER_LOGIN_STATUS = {
    LOGGED_IN: "loggedin",
    LOGGED_OUT: "loggedout"
};
export const USER_LOGIN_MEDIUM = {
    PHONE: "phone",
    NONE: ""
};

export const PROMISE_STATUS = {
    FULFILLED: "fulfilled",
    REJECTED: "rejected",
    PENDING: "pending"
};
export const HOW_IT_WORKS_VIDEO_URL = `${staticFastlyAppUrl()}/india/homepage/How_to_buy_from_CARS24.mp4`;

//todo
export const routeToEventCategoryMapping = {
    "buy-used-cars": "Buy_used_cars_B2C_Homepage",
    "car-listing": "Buy_used_cars_B2C_Category_Page",
    "car-details": "Buy_used_cars_B2C_Car_Details_Page",
    "faq": "FAQs",
    "my-bookings": "MyCars",
    "why-cars24": "Why_Cars24",
    "facebook-landing": "Buy_used_cars_B2C_Marketing_Page",
    "buy-used-cars-home": "Buy_used_cars_India_Home_Page",
    "sell-marketing": "Sell_marketing",
    "rto": "rto"
};

export const routeToEventCategoryMappingKeys = { ...transformKeys(routeToEventCategoryMapping) };

export const B2C_PAGE_NAME = {
    "buy-used-cars": "Homepage",
    "home": "Homepage",
    "my-bookings": "MyCars",
    "my-wishlist": "MyCars",
    "car-listing": "Listing Page",
    "car-detail": "Car Detail Page",
    "wishist": "wishlist",
    "pending-bookings": "pending_bookings",
    "test-drives": "test_drives",
    "purchased": "purchased"
};

export const PAGE_SOURCE = {
    CHECKOUT: "CHECKOUT",
    LISTING: "LISTING"
};

export const FOURDOOR_SOURCE = {
    MSITE: "msite_fourdoor",
    DESKTOP: "website_fourdoor"
};

export const WHITE_LOGO_URL = "https://s3.ap-south-1.amazonaws.com/com.cars24.images/production/india/homepage/cars24_web_super.png";

export const dateFormat = {
    day: "2-digit",
    month: "short",
    year: "numeric"
};
