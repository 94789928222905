
import React, { useEffect, useState, useRef } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import ArrowIcon from "../../shared/arrow";
import Logo from "../../shared/logo";
import mapPin from "./images/map-pin.svg";
import userIcon from "./images/user-icon.svg";
import downArrow from "./images/down-arrow.svg";
import { LOCATION_PAGE_TYPE } from "../location-picker-popup/constant";
import { findSubstringInArrayByRegex } from "../../../utils/helpers/find-substring-in-array";
import { B2C_PAGE_NAME, CTA_WITHOUT_PINCODE_PAGES, HIDE_POPUP_FOR_URLS, MODAL_USER_ACTION, NUMBER, USER_LOGIN_MEDIUM, USER_LOGIN_STATUS } from "../../../constants/app-constants";
import { Link } from "react-router-dom";
import { ITEM_BLOCKS, IDENTIFIERS } from "../../../utils/helpers/product-flow-tabs";
import { FORM_SOURCE } from "../interest-form/constants";
import { redirect } from "../../../constants/app-constants";
import { getOtherAnchorURL } from "../../../utils/helpers/get-sell-anchor-url";
import { sendCustomGaEvents } from "../../../tracking";
import {
    onClickLocationPicker,
    carFinanceClick,
    wishlistHeaderClick,
    AccountTabLogin,
    appoinmentOnClick,
    bookingOnClick,
    onClickLogo,
    editAccount,
    resOnClick,
    sellOnClick,
    logOutOnClick,
    gaEventsMap,
    myOrderClicked
} from "./tracking";
import { appUrl } from "../../../constants/url-constants";
import { RESOURCES_LINKING } from "../../../constants/used-car-constants";
import Button from "../../shared/button";
import mobileLastDigit from "../../../utils/helpers/mobile-last-digit";
import withDesktopLogin from "../with-desktop-login";
import LoginSignup from "../login-signup-v2";
import useScrollToggle from "../../../hooks/use-scroll-toggle";
import Skeleton from "./skeleton";
import IconHeart from "../../shared/icon-heart";
import { WISHLIST_LOGIN_HEADING, BOOKINGS_ROUTE, MY_CARS_ROUTES } from "./constants";
import { getGaEventDimensionObject } from "../../../utils/helpers/get-ga-event-object";
import { LOGIN_SOURCE } from "../login-signup-v2/constants";
import LazyImage from "../../shared/lazy-image";
import { ga4Signout } from "../../../constants/tracking-constants/ga4-ecommerce-event";
import { CF_SEO_LINKS } from "../../../constants/cf-seo-link-constants";
import { CAR_SERVICE_URLS } from "../../../constants/car-services-constants";
import { sellCarLinks } from "../../../constants/tracking-constants/sell-car-link-constants";
import { DesktopNavbarDropdown }  from "@cars24/turbo-web/lib/desktop-navbar-dropdown";
import { DesktopBuyCarParser, DesktopCarServicesParser, DesktopCFSeoLinkParse, DesktopNewCarsParser, DesktopSellCarParser, HeaderParserManager } from "../../../utils/helpers/combined-header-options-parser";
import { BodyText } from "@cars24/turbo-web/lib/body-text";
import { TinyText } from "@cars24/turbo-web/lib/tiny-text";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import { preventPropagation } from "../../../utils/helpers/prevent-propagation";
import Filters from "../../../utils/filters-v3";
import { LISTING_PAGE_LANDING_SOURCE } from "../car-list/constants";

const HEADER_MOVABLE_ROUTES = [
    "car-detail"
];
// eslint-disable-next-line complexity, max-statements
const HeaderV2 = ({
    fetchPresetFiltersConnect,
    selectedCity,
    route,
    toggleLocationPickerConnect,
    locationPageTypeConnect,
    selectedCity: {
        name: locationName,
        id: selectedCityID
    },
    history,
    isLoggedIn,
    userName,
    logoutUser,
    isPopularCityListSSR,
    fetchCityListAndBrandsConnect,
    updatePopularCityListSSRStatusConnect,
    pincode,
    mobile,
    email,
    activeHeaderTab,
    curatedPicks = [],
    updateHomePageSSRStatusConnect,
    buyUsedSSR,
    isFetched = false,
    cityList,
    wishlistCount,
    cityName,
    cityId,
    userId,
    headerVisibility,
    isServiceable,
    showLocationPicker,
    isNearByCityAvailable,
    isIndiaPage,
    buyCarsHeaderData,
    buyCarsHeaderDataSSR,
    updateSSRConnect,
    fetchBuyCarsHeaderDataConnect,
    setIsCTAClickedWithoutPincodeConnect = () => {},
    appliedOptions,
    reloadCarListConnect = () => {},
    isCTAClickedWithoutPincode

}) => {
    const {
        name: routeName,
        isUsedCarRoute
    } = route || {};
    const { pathname } = history.location;
    const queryParams = parseLocationSearchParams(history.location.search);
    // const [redirectUrl, setredirectUrl] = useState(appUrl());
    const [showResourceMenu, setShowResourceMenu] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [activeTab, setActiveTab] = useState(activeHeaderTab);
    const [loginHeading, setLoginHeading] = useState("Log in to continue");
    const isHomePageRoute = (routeName === "buy-used-cars-home");
    const isBuySellHomePage = (routeName === "home" || routeName === "buy-used-cars");
    const isMyBookingsRoute = routeName === "myBookings";
    const isSuperHomeRoute = routeName === "buy-used-cars";
    const isMyCarsRoute = routeName && MY_CARS_ROUTES.includes(routeName);
    const headerTopRef = useRef(null);
    const [loginSource, setLoginSource] = useState(LOGIN_SOURCE.HEADER);
    const [isMenuOpen, seIsMenuOpen] = useState(false);
    const [iconsLoaded, setIconsLoaded] = useState(false);
    const iconsRef = useRef({});
    const isHeaderTransition = routeName && routeName.includes(HEADER_MOVABLE_ROUTES);
    const isSeoUrl = ((route || {}).pathname || "").replace(/\//g, "") !== "buy-used-car";
    const [isHeaderHovered, setIsHeaderHovered] = useState(false);

    const handleHeaderHover = () => {
        if (!isHeaderHovered) {
            setIsHeaderHovered(true);
        }
    };

    const handleBuyCarOnlineClick = () => {
        history.push(`/buy-used-cars/`);
    };

    const isSticky = useScrollToggle({
        threshold: -NUMBER.TWO_HUNDRED,
        ref: headerTopRef
    });
    const isLandingPage = routeName === "buy-used-cars";

    const getPageName = () => isMyBookingsRoute ? B2C_PAGE_NAME[BOOKINGS_ROUTE] : B2C_PAGE_NAME[routeName] || routeName;

    const changeLocation = () => {
        if (!isServiceable && !isNearByCityAvailable && showLocationPicker) {
            return;
        }
        toggleLocationPickerConnect();
        if (LOCATION_PAGE_TYPE[routeName]) {
            locationPageTypeConnect(LOCATION_PAGE_TYPE[routeName]);
        }

        sendCustomGaEvents({
            ...onClickLocationPicker,
            eventLabel: locationName
        });
    };

    useEffect(() => {
        if (!buyCarsHeaderDataSSR) {
            fetchBuyCarsHeaderDataConnect(selectedCityID);
        } else {
            updateSSRConnect(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCityID && selectedCityID.toString()]);

    useEffect(() => {
        if (isHomePageRoute || isUsedCarRoute) {
            setActiveTab(IDENTIFIERS.BUY);
        } else if (routeName === "home" || routeName === "sell-online") {
            setActiveTab(IDENTIFIERS.SELL);
        } else if (routeName === "used-car-loan") {
            setActiveTab(IDENTIFIERS.LOAN);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routeName, isUsedCarRoute]);

    useEffect(() => {
        if (!pincode && isSticky && isLandingPage) {
            toggleLocationPickerConnect(true);
            if (LOCATION_PAGE_TYPE[routeName]) locationPageTypeConnect(LOCATION_PAGE_TYPE[routeName]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSticky]);

    useEffect(() => {

        if (!buyUsedSSR && isServiceable) {
            fetchPresetFiltersConnect({ cityId: selectedCity.id || "" });
        } else {
            updateHomePageSSRStatusConnect(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCity, isServiceable]);
    const dimensionObject = getGaEventDimensionObject({
        isLoggedIn,
        cityId,
        cityName,
        userId
    });

    const isLocationPickerVisible = () => {
        return !findSubstringInArrayByRegex({
            haystack: HIDE_POPUP_FOR_URLS,
            needle: pathname
        }).elementExists;
    };

    useEffect(() => {
        if (!isPopularCityListSSR) {
            fetchCityListAndBrandsConnect();
        } else {
            updatePopularCityListSSRStatusConnect(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchCityListAndBrandsConnect, updatePopularCityListSSRStatusConnect]);

    useEffect(() => {
        if (isMenuOpen && Object.keys(iconsRef.current).length === 0 && !iconsLoaded) {
            import(/* webpackChunkName: "login-panel-icons" */ "./icons").then(res => {
                iconsRef.current = res.default();
                setIconsLoaded(true);
            });
            // console.warn("iconsRef.current", iconsRef.current);
        }
    }, [iconsLoaded, isMenuOpen]);

    useEffect(() => {
        let timeoutId;
        if (queryParams.source === "locationSelector") {
            timeoutId = window.setTimeout(() => changeLocation(), NUMBER.FIVE_HUNDRED);
        }
        return () => window.clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams.source]);

    const onClickFinance = () => {
        sendCustomGaEvents({
            ...carFinanceClick,
            eventLabel: getPageName(),
            ...dimensionObject
        });
        history.push(`/used-car-loan/?fromSource=${window.location.pathname}`);
    };

    const onWishlistIconClick = () => {
        sendCustomGaEvents({
            ...wishlistHeaderClick,
            eventLabel: getPageName(),
            eventAction: `${wishlistCount > NUMBER.ZERO && isLoggedIn ? "wishlist" : "no wishlist"} | select`,
            ...dimensionObject
        });
        if (isLoggedIn) {
            history.push("/wishlist");
        } else {
            setLoginSource(LOGIN_SOURCE.WISHLIST_HEADER);
            setShowLogin(true);
            setLoginHeading(WISHLIST_LOGIN_HEADING);
        }
    };

    const handleLoginSignup = () => {
        if (!isLoggedIn) {
            setShowLogin(true);
            setLoginHeading("Log in to continue");
        }
        sendCustomGaEvents({
            ...AccountTabLogin,
            ...dimensionObject
        });
    };
    const handleAppointment = () => {
        if (isLoggedIn) {
            window.open(redirect.AUTH_APPOINTMENTS, "_self");
        } else {
            setShowLogin(true);
            setLoginHeading("Log in to continue");
        }
        sendCustomGaEvents({
            ...appoinmentOnClick,
            ...dimensionObject
        });
    };

    const handleMyBookings = () => {
        if (isLoggedIn) {
            window.open(redirect.MY_BOOKINGS, "_self");
        } else {
            setShowLogin(true);
            setLoginHeading("Log in to view your bookings");
        }
        sendCustomGaEvents(bookingOnClick);
    };

    const handleMyOrders = () => {
        if (isLoggedIn) {
            window.open(redirect.MY_ORDERS, "_self");
        } else {
            setShowLogin(true);
            setLoginHeading("Log in to continue");
        }
        sendCustomGaEvents(myOrderClicked);
    };

    const handleCommunicationPreferences = () => {
        if (isLoggedIn) {
            window.open(redirect.COMMUNICATION_PREFERENCES, "_self");
        } else {
            setShowLogin(true);
            setLoginHeading("Log in to continue");
        }
        // sendCustomGaEvents(myOrderClicked);
    };

    const onClickHandler = ({ url, desktopEvent = null }) => {
        if (desktopEvent) {
            sendCustomGaEvents(gaEventsMap[desktopEvent]);
        }
        window.location.href = url;
    };

    const pageURL = typeof window !== "undefined" ? window.location.href : "";

    const handleOnClickLogo = () => {
        sendCustomGaEvents({
            ...onClickLogo,
            dimension16: pageURL,
            dimension88: pincode ? pincode : "N/A",
            dimension91: "N/A",
            dimension116: mobile ? mobileLastDigit(mobile) : "N/A"
        });
    };

    const handleCallback = () => {
        if (loginHeading === "Log in to check your loan eligibility") {
            history.push(`/finance/personal-details?redirectTo=${window.location.pathname}&cfSource=${FORM_SOURCE.HOME_PAGE}`);
        } else if (loginHeading === WISHLIST_LOGIN_HEADING) {
            history.push("/wishlist");
        } else {
            setShowLogin(false);
        }
    };

    const onCloseLoginScreen = () => {
        setShowLogin(false);
        setLoginSource(LOGIN_SOURCE.HEADER);
    };

    const loginProps = {
        isVisible: showLogin,
        onClose: onCloseLoginScreen,
        heading: loginHeading,
        onLoginSuccessCallback: handleCallback,
        source: loginSource
    };

    const onEditProfileClick = () => {
        window.location.href = redirect.AUTH_PROFILE;
        sendCustomGaEvents({
            ...editAccount,
            ...dimensionObject
        });
    };

    const onLinkClickHandler = (url, option, label) => {
        window.location.href = url;
        sendCustomGaEvents({
            ...gaEventsMap[option],
            eventLabel: label,
            ...dimensionObject
        });
    };

    const handleOnClickRes = () => {
        setShowResourceMenu(!showResourceMenu);
        sendCustomGaEvents({
            ...resOnClick,
            ...dimensionObject
        });
    };

    const logoutCallback = () => {
        if (isMyCarsRoute) {
            history.push("/");
        }
    };

    const handleLogOut = () => {
        logoutUser({ logoutCallback });
        sendCustomGaEvents({
            ...ga4Signout,
            user_id: mobile,
            user_status: USER_LOGIN_STATUS.LOGGED_OUT,
            login_type: USER_LOGIN_MEDIUM.NONE
        });
        sendCustomGaEvents({
            ...logOutOnClick,
            ...dimensionObject
        });
    };

    const getLoginPanel = () => {

        if (!isFetched) {
            return <Skeleton />;
        } else if (!isLoggedIn) {
            return (
                <div className={"media-body"} styleName={"styles.account"}>
                    <TinyText color="var(--grey-900)">Hello, Sign in</TinyText>
                    <strong>  <BodyText color="var(--grey-900)" isBold level={3}>Account</BodyText> <img width="18" height="19" className={"ml-2"} src={downArrow} alt="Account" /></strong>
                </div>
            );
        } else {
            return (
                <div className={"media-body"} styleName={"styles.account styles.userName"}>
                    <TinyText color="var(--grey-900)">Hello, {userName}</TinyText>
                    <strong><BodyText color="var(--grey-900)" isBold level={3}>Account </BodyText><img width="18" height="19" className={"ml-2"} src={downArrow} alt="Account" /></strong>
                </div>
            );
        }
    };

    const getCityName = (id) => {

        const cityData = cityList.find(city => city.city_id === id);

        if (cityData) {
            return cityData.city_name;
        } else {
            return null;
        }
    };

    const fetchLocation = () => {
        if (locationName) {
            return locationName;
        } else if (selectedCityID) {
            return getCityName(selectedCityID);
        } else {
            return "Set Location";
        }
    };

    const handleOnClickSell = () => {
        if (isBuySellHomePage) {
            sendCustomGaEvents({
                ...sellOnClick,
                eventLabel: "Homepage",
                ...dimensionObject
            });
        } else {
            sendCustomGaEvents({
                ...sellOnClick,
                eventLabel: "Listing Page",
                ...dimensionObject
            });
        }

    };

    const setModalState = () => {
        if (!isMenuOpen) seIsMenuOpen(true);
    };

    const { data: buyCarsLinkData = [] } = buyCarsHeaderData || {};
    const dropdownParser = new HeaderParserManager();
    const currentSelectedLocation = locationName ?? "India";
    // eslint-disable-next-line new-cap

    const handleCategoryPageRedirect = (e) => {
        if (!pincode) {
            toggleLocationPickerConnect(true);
            locationPageTypeConnect(LOCATION_PAGE_TYPE.homePage);
            setIsCTAClickedWithoutPincodeConnect({
                type: CTA_WITHOUT_PINCODE_PAGES.HEADER_BUY_USED_CARS
            });
            return;
        }
        const { query } = {};
        if (e) preventPropagation(e);
        const appliedFilters = new Filters({ cityID: selectedCityID, pincode });
        const listingUrl = appliedFilters.getListingUrl({
            ...(query && { filters: query }),
            options: {
                ...appliedOptions,
                listingSource: LISTING_PAGE_LANDING_SOURCE.Homepage_Filters
            }
        }).relativeURL;
        reloadCarListConnect(true);
        history.push(listingUrl);
        setIsCTAClickedWithoutPincodeConnect({
            type: null
        });
    };

    useEffect(() => {
        if (isCTAClickedWithoutPincode && isCTAClickedWithoutPincode.type === CTA_WITHOUT_PINCODE_PAGES.HEADER_BUY_USED_CARS && selectedCityID) {
            handleCategoryPageRedirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCityID && selectedCityID.toString()]);

    return (
        <React.Fragment>
            <LoginSignup {...loginProps}>
                <header
                    // eslint-disable-next-line no-nested-ternary
                    styleName={`styles.headerWrapper ${isHeaderTransition ? headerVisibility ? "" : "styles.hideHeader" : ""}`}>
                    <div className="container" styleName={"styles.headerContainer"}>
                        <div styleName={"styles.leftNavWrapper"}>
                            <div styleName={"styles.logoWrapper"} onClick={handleOnClickLogo}>
                                <a href={"/"} className={"d-inline-block"} styleName={"styles.logo"}> <Logo width="80" height="39" /></a>
                            </div>

                            {/* City change dropdown */}
                            {
                                isLocationPickerVisible() &&
                                <div styleName={`styles.dropdownWrapper ${isSeoUrl && isIndiaPage ? "styles.greyText" : ""}`} onClick={changeLocation}>
                                    <p>
                                        <img width="18" height="18" className={"mr-2"} src={mapPin} alt="city" />
                                        {fetchLocation()}
                                    </p>
                                    <ArrowIcon width="24" height="24" black={"BlackArrow"} />
                                </div>
                            }
                        </div>

                        {/* Main menu */}
                        <div styleName={"styles.mainMenu"}>
                            <ul styleName={"styles.navList"}>
                                <li styleName={`styles.carFindWrapper ${(isUsedCarRoute && !isSuperHomeRoute) ? "styles.active" : ""}`} >
                                    <div data-event="find_car" onClick={handleCategoryPageRedirect}>
                                        <div styleName={`styles.hoverText`} onMouseEnter={handleHeaderHover}>
                                            {
                                                (isUsedCarRoute && !isSuperHomeRoute) ? (
                                                    <BodyText level={3} color="var(--orange-500)" isBold>Buy used car</BodyText>
                                                ) : (
                                                    <BodyText level={3}>Buy used car</BodyText>
                                                )
                                            }
                                            {buyCarsLinkData && !!buyCarsLinkData.length && <ArrowIcon black={"BlackArrow"} width="24" height="24" />}
                                        </div>
                                    </div>
                                    {
                                        buyCarsLinkData && !!buyCarsLinkData.length &&
                                        <div styleName={"styles.loginDropdownfirst styles.carFind "}>
                                            <div styleName={"styles.headerDropDownContainer"}>
                                                <DesktopNavbarDropdown dropdownData={dropdownParser.execute(new DesktopBuyCarParser({buyCarsHeaderData, currentSelectedLocation, handleBuyCarOnlineClick, changeLocation, isOpen: isHeaderHovered}))}/>
                                            </div>
                                        </div>
                                    }
                                </li>
                                <li styleName={`styles.sellCarWrapper ${activeTab === IDENTIFIERS.SELL ? "styles.active" : ""}`} onClick={handleOnClickSell}>
                                    <Link to={ITEM_BLOCKS[IDENTIFIERS.SELL].redirectURL}>
                                        <div styleName={`styles.hoverText`} onMouseEnter={handleHeaderHover}>
                                            {
                                                activeTab === IDENTIFIERS.SELL ? (
                                                    <BodyText level={3} color="var(--orange-500)" isBold>Sell car</BodyText>
                                                ) : (
                                                    <BodyText level={3}>Sell car</BodyText>
                                                )
                                            }
                                            {sellCarLinks && !!sellCarLinks.length && <ArrowIcon black={"BlackArrow"} width="24" height="24" />}
                                        </div>
                                    </Link>
                                    {
                                        sellCarLinks && !!sellCarLinks.length && (
                                            <div styleName={`styles.loginDropdownfirst styles.sellCar`}>
                                                <div styleName={"styles.headerDropDownContainer"}>
                                                    <DesktopNavbarDropdown dropdownData={dropdownParser.execute(new DesktopSellCarParser(sellCarLinks))}/>
                                                </div>
                                            </div>
                                        )
                                    }
                                </li>
                                <li styleName={`styles.carFinanceWrapper ${activeTab === IDENTIFIERS.LOAN ? "styles.active" : ""}`}>
                                    <span onClick={onClickFinance}>
                                        <div styleName={`styles.hoverText`} onMouseEnter={handleHeaderHover}>
                                            {
                                                activeTab === IDENTIFIERS.LOAN ? (
                                                    <BodyText level={3} color="var(--orange-500)" isBold>Car finance</BodyText>
                                                ) : (
                                                    <BodyText level={3}>Car finance</BodyText>
                                                )
                                            }
                                        </div>
                                    </span>
                                    {CF_SEO_LINKS && CF_SEO_LINKS.length > 0 && <ArrowIcon black={"BlackArrow"} width="24" height="24" />}

                                    {CF_SEO_LINKS && CF_SEO_LINKS.length > 0 && (
                                        <div styleName={"styles.loginDropdownfirst styles.carFinance"}>
                                            <div styleName={"styles.headerDropDownContainer"}>
                                                <DesktopNavbarDropdown dropdownData={dropdownParser.execute(new DesktopCFSeoLinkParse(CF_SEO_LINKS))}/>
                                            </div>
                                        </div>
                                    )}

                                </li>
                                <li styleName={"styles.newCarsWrapper"}>

                                    <a href={`${appUrl()}/new-cars/`}>
                                        <div styleName={"styles.hoverText"} onMouseEnter={handleHeaderHover}>
                                            <BodyText level={3}> New cars</BodyText>
                                        </div>
                                    </a>
                                    {curatedPicks && !!curatedPicks.length && <ArrowIcon black={"BlackArrow"} width="24" height="24" />}

                                    {
                                        curatedPicks && !!curatedPicks.length && (
                                            <div styleName={"styles.loginDropdownfirst styles.newCars"}>
                                                <div styleName={"styles.headerDropDownContainer"}>
                                                    <DesktopNavbarDropdown dropdownData={dropdownParser.execute(new DesktopNewCarsParser({isOpen: isHeaderHovered}))} />
                                                </div>
                                            </div>
                                        )
                                    }

                                </li>
                                <li styleName={"styles.carServiceWrapper"}>
                                    <span>
                                        <div styleName={"styles.hoverText"} onMouseEnter={handleHeaderHover}>
                                            <BodyText level={3}>Car services</BodyText>
                                        </div>
                                    </span>
                                    <ArrowIcon black={"BlackArrow"} width="24" height="24" />
                                    <div styleName={"styles.loginDropdownfirst styles.carServiceBox"}>
                                        <div styleName={"styles.headerDropDownContainer"}>
                                            <DesktopNavbarDropdown dropdownData={dropdownParser.execute(new DesktopCarServicesParser(CAR_SERVICE_URLS))}/>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        {/* Account navigation */}
                        <div styleName={"styles.navRightWrapper"}>
                            {isUsedCarRoute &&
                                <div styleName={"styles.heartWrap"} onClick={onWishlistIconClick}>
                                    <IconHeart />
                                    {wishlistCount > NUMBER.ZERO && isLoggedIn && <span styleName={"styles.wishlist"} />}
                                </div>
                            }
                            <div styleName={"styles.dropdownMenu"}
                                onMouseEnter={setModalState}
                            >
                                <div className={"media align-items-center"}>
                                    <img width="24" height="24" src={userIcon} alt="User" />
                                    {getLoginPanel()}
                                </div>

                                {/* Account navigation dropdown */}
                                < div styleName={"styles.dropdownMenuWrap"} >
                                    {
                                        !isLoggedIn &&
                                        <Button
                                            text={"LOG IN/SIGN UP"}
                                            onClick={handleLoginSignup}
                                        />
                                    }
                                    {
                                        isLoggedIn &&
                                        <div styleName={"styles.userLoginDetail"}>
                                            <strong>Hey, {userName || "Customer"}</strong>
                                            <div className={"d-flex justify-content-between"}>
                                                <div>
                                                    {mobile && <span>+91 {mobile}</span>}
                                                    {email && <span>{email}</span>}
                                                </div>
                                                <LazyImage width="18" height="18" src={iconsRef.current.EditIcon} alt="Edit" onClick={onEditProfileClick} />
                                            </div>
                                        </div>
                                    }
                                    <div styleName={"styles.appointmentsWrapper"}>
                                        <div styleName={"styles.appWrapper"} onClick={handleAppointment}>
                                            <div styleName={"styles.sellStrip"}>
                                                <LazyImage width="24" height="24" src={iconsRef.current.SellAppIcon} alt="sell" />
                                                <p>My Appointments</p>
                                            </div>
                                            <span styleName={"styles.sellStripText"}>SELL</span>
                                        </div>
                                        <div styleName={"styles.appWrapper"} onClick={handleMyBookings}>
                                            <div styleName={"styles.sellStrip"}>
                                                <LazyImage width="24" height="24" src={iconsRef.current.BuyAppIcon} alt="buy" />
                                                <p>My Bookings</p>
                                            </div>
                                            <span styleName={"styles.sellStripText"}>BUY</span>
                                        </div>
                                        <div styleName={"styles.appWrapper"} onClick={handleMyOrders}>
                                            <div styleName={"styles.sellStrip"}>
                                                <LazyImage width="24" height="24" src={iconsRef.current.MyOrderIcon} alt="my orders" />
                                                <p>My Orders</p>
                                            </div>
                                        </div>
                                        <div styleName={"styles.appWrapper"} onClick={handleCommunicationPreferences}>
                                            <div styleName={"styles.sellStrip"}>
                                                <LazyImage width="24" height="24" src={iconsRef.current.CommunicationIcon} alt="communication preferences" />
                                                <p>Communication Preferences</p>
                                            </div>
                                        </div>
                                    </div>
                                    <ul styleName={"styles.overlay"}>
                                        <li onClick={handleOnClickRes} styleName={"styles.resourcesBorder"}>
                                            <div className={"d-flex justify-content-between"}>
                                                <span>
                                                    <LazyImage width="24" height="24" src={iconsRef.current.ResourcesIcon} alt="Resources" />
                                                    Resources
                                                </span>
                                                <ArrowIcon width="24" height="24" silver={true} rotateBy={showResourceMenu ? NUMBER.ONE_HUNDRED_EIGHTY : 0} />
                                            </div>
                                            {showResourceMenu &&
                                                <ul styleName={"styles.subMenu"}>
                                                    {
                                                        RESOURCES_LINKING.map((link, idx) => (
                                                            <li onClick={() => onLinkClickHandler(getOtherAnchorURL(link.value, MODAL_USER_ACTION.RESOURCES), "res", link.displayText)} key={idx}>
                                                                <a href={getOtherAnchorURL(link.value, MODAL_USER_ACTION.RESOURCES)}>
                                                                    {link.displayText}
                                                                </a>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            }
                                        </li>
                                        <li onClick={() => onClickHandler({ url: redirect.RC_TRANSFER, desktopEvent: "rCTransferOnClick" })}>
                                            <span>

                                                <LazyImage width="24" height="24" src={iconsRef.current.RcIcon} alt="RcIcon" />
                                                <a href={redirect.RC_TRANSFER}>
                                                    RC Transfer Status
                                                </a>

                                            </span>
                                        </li>
                                        <li onClick={() => onClickHandler({ url: redirect.BECOME_OUR_PARTNER, desktopEvent: "becomeOurPartnerOnClick" })}>
                                            <span>
                                                <LazyImage width="24" height="24" src={iconsRef.current.PartnerIcon} alt="Partner" />
                                            </span>
                                            <a href={redirect.BECOME_OUR_PARTNER}>
                                                Become Our partner
                                            </a>

                                        </li>
                                        <li onClick={() => onClickHandler({ url: redirect.FRANCHISE })}>
                                            <span>
                                                <LazyImage width="24" height="24" src={iconsRef.current.FranchiseIcon} alt="Franchise" />
                                            </span>
                                            <a href={redirect.FRANCHISE}>
                                                Become a CARS24 Franchise
                                            </a>

                                        </li>
                                        <li onClick={() => onClickHandler({ url: "/faq#buy", desktopEvent: "faqOnClick" })}>
                                            <span>
                                                <LazyImage width="24" height="24" src={iconsRef.current.HelpIcon} alt="Help" />
                                            </span>
                                            <Link to={"/faq/#buy"}>FAQ</Link>

                                        </li>
                                        {
                                            isLoggedIn &&
                                            <li onClick={handleLogOut}>
                                                <a>
                                                    <LazyImage width="24" height="24" className={"mr-3"} src={iconsRef.current.LogoutIcon} alt="Logout" />
                                                    Logout
                                                </a>
                                            </li>
                                        }
                                    </ul >
                                </div >
                            </div >
                        </div >
                    </div >
                </header >
            </LoginSignup >
        </React.Fragment >
    );
};

HeaderV2.propTypes = {
    fetchPresetFiltersConnect: PropTypes.func,
    selectedCity: PropTypes.object,
    route: PropTypes.object,
    toggleLocationPickerConnect: PropTypes.func,
    locationPageTypeConnect: PropTypes.func,
    history: PropTypes.object,
    activeHeaderTab: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    userName: PropTypes.string,
    isFetched: PropTypes.bool,
    logoutUser: PropTypes.func,
    isPopularCityListSSR: PropTypes.bool,
    fetchCityListAndBrandsConnect: PropTypes.func,
    updatePopularCityListSSRStatusConnect: PropTypes.func,
    pincode: PropTypes.string,
    mobile: PropTypes.string,
    email: PropTypes.string,
    curatedPicks: PropTypes.array,
    updateHomePageSSRStatusConnect: PropTypes.func,
    buyUsedSSR: PropTypes.bool,
    cityList: PropTypes.array,
    wishlistCount: PropTypes.number,
    cityName: PropTypes.string,
    cityId: PropTypes.number,
    userId: PropTypes.string,
    headerVisibility: PropTypes.bool,
    isServiceable: PropTypes.bool,
    showLocationPicker: PropTypes.bool,
    isNearByCityAvailable: PropTypes.bool,
    isIndiaPage: PropTypes.bool,
    buyCarsHeaderData: PropTypes.object,
    buyCarsHeaderDataSSR: PropTypes.bool,
    updateSSRConnect: PropTypes.func,
    fetchBuyCarsHeaderDataConnect: PropTypes.func,
    setIsCTAClickedWithoutPincodeConnect: PropTypes.func,
    appliedOptions: PropTypes.object,
    reloadCarListConnect: PropTypes.func,
    isCTAClickedWithoutPincode: PropTypes.object
};

export default withDesktopLogin(HeaderV2);
