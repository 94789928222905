/* eslint-disable max-params */
/* eslint-disable max-statements */
import queryString from "querystring";
import { API_PATH_ALTERNATE } from "../constants/api-constants";
import middlewareConfig from "./middleware-config";
import { MIDDLEWARE_ENUMS } from "./middleware-constants";

const {
    OTP_SEND,
    POST_SELL_ONLINE_OFFER_INPUT,
    SELL_ONLINE_PRICE_FEEDBACK,
    SELL_ONLINE_CALLBACK_LEAD,
    LEAD_CREATE,
    LEAD_UPDATE,
    LEAD_DETAILS,
    OTP_VERIFY,
    SELL_ONLINE_QUESTION,
    GET_SELL_ONLINE_OFFER_PRICE,
    DSA_DETAIL,
    DSA_TWO_STEP_APPOINTMENT,
    CREATE_ORDER,
    LEAD_WITHOUT_PHONE,
    APPOINTMENT_DETAILS_BY_TOKEN,
    APPOINTMENT_DETAILS_BY_ID,
    CANCEL_APPOINTMENT,
    BANNER_WIDGET,
    GS_DENY,
    GET_QUESTION_FOR_USER_INTENT,
    REQUEST_CALLBACK_APPOINTMENT,
    WHATSAPP_CONFIRMATION,
    APPOINTMENT_RESCHEDULE,
    DUPLICATE_APPOINTMENT,
    RE_VERIFY_APPOINTMENT,
    VERIFY_AUTH_USER_LEAD,
    MY_APPOINTMENTS,
    SAVE_USER_EMAIL,
    UPDATE_LEAD_DETAIL,
    RTO_LEAD,
    SCHEDULE_CONSULTATION,
    DUPLICATE_INSPECTED_APPOINTMENT,
    PROSPECT_LEAD,
    CHALLAN_LIST,
    CAR_SERVICE_HISTORY,
    REPEAT_INSPECTION,
    SCRAP_CAR,
    SAVE_SCRAP_CAR_QUESTION,
    CC_DROP_LEAD,
    CAR_WASH_SLOTS,
    BOOK_CAR_WASH,
    CANCEL_CAR_WASH,
    CHANNEL_PARTNER_LEAD,
    MARK_DOC_NOT_UPLOADD,
    INSPECTION_LEAD,
    PRE_DELIVERY_INSPECTION_ORDER,
    PRE_DELIVERY_INSPECTION_PRICE,
    FRANCHISE,
    PRODUCT_CAR_HEALTH,
    CHR_LEAD_DETAILS,
    CAR_HEALTH_CREATE_ORDER,
    CAMPAIGN_TOKEN,
    LEAD_ATTRIBUTES,
    STATE_CHALLAN_OTP,
    PRE_DELIVERY_INSPECTION_PRICE_ALL
} = API_PATH_ALTERNATE;

const leadServiceJavaApi = (api) => {

    const postUserChoice = (payload) => {
        return api.post(`customer-quote-price-input`, payload);
    };

    const postc24QFeedback = (payload) => {
        return api.post(`seller-price-feedback`, payload);
    };

    const postOfferPriceInput = (payload) => {
        return api.post(POST_SELL_ONLINE_OFFER_INPUT, payload);
    };
    const postSellOnlineFeedback = (payload) => {
        return api.post(SELL_ONLINE_PRICE_FEEDBACK, payload);
    };

    const sellOnlineCallBack = (payload) => {
        return api.post(SELL_ONLINE_CALLBACK_LEAD, payload);
    };

    //post request to create a lead
    const createLead = (query, params, userToken, isMsite = false) => {
        const config = {
            headers: {
                "deviceCategory": isMsite ? "m-web" : "web",
                ...(userToken && { "pvtAuthorization": userToken })
            }
        };

        return api.post(`${LEAD_CREATE}?${query}`, params, config);
    };

    const updateLead = (query, params) => {
        const config = {
            headers: {
                ...(params.pvtAuth && { "pvtAuthorization": params.pvtAuth })
            }
        };
        return api.put(`${LEAD_UPDATE}?${query}`, params, config);
    };

    const fetchLeadDetails = (token, params = {}, isMsite = false, pvtToken) => {
        return api.get(`v2${LEAD_DETAILS}/${token}`, {
            params,
            parse: true,
            headers: {
                "deviceCategory": isMsite ? "m-web" : "web",
                pvtAuthorization: pvtToken
            }
        });
    };
    const fetchLeadDetailsv1 = (token, params = {}, isMsite = false, pvtToken) => {
        return api.get(`${LEAD_DETAILS}/${token}`, {
            params,
            parse: true,
            headers: {
                "deviceCategory": isMsite ? "m-web" : "web",
                pvtAuthorization: pvtToken
            }
        });
    };

    const postSellOnlineQuestion = (data) => {
        return api.post(`${SELL_ONLINE_QUESTION}`, data);
    };

    const getSellOnlinePrice = (appointmentId) => {
        return api.get(`${GET_SELL_ONLINE_OFFER_PRICE}?appointmentId=${appointmentId}`);
    };

    const getDsaDetail = (source) => {
        return api.get(`${DSA_DETAIL}/${source}`);
    };

    const postDsaTwoStepAppointment = (src, token, payload) => {
        return api.post(`${DSA_TWO_STEP_APPOINTMENT}?token=${token}&src=${src}`, payload);
    };

    const updateDsaTwoStepAppointment = (src, token, payload) => {
        return api.put(`${DSA_TWO_STEP_APPOINTMENT}?token=${token}&src=${src}`, payload);
    };

    const createAppointment = (token, params, query = {}, headers) => {
        const stringifiedQuery = queryString.stringify({
            pay: "skip",
            ...query
        });
        return api.post(`${DSA_TWO_STEP_APPOINTMENT}?token=${token}&${stringifiedQuery}`, params, { headers: headers || {} });
    };

    const updateAppointment = (token, params, query = {}, headers) => {
        const stringifiedQuery = queryString.stringify({
            pay: "skip",
            ...query
        });
        return api.put(`${DSA_TWO_STEP_APPOINTMENT}?token=${token}&${stringifiedQuery}`, params, { headers: headers || {} });
    };

    const createOneStepAppointment = (params, query = {}) => {
        const ONE_STEP_APPOINTMENT = DSA_TWO_STEP_APPOINTMENT;
        const stringifiedQuery = queryString.stringify({
            pay: "skip",
            ...query
        });
        return api.post(`${ONE_STEP_APPOINTMENT}?oneStep=true&${stringifiedQuery}`, params);
    };

    const submitOrderRequest = (params, query = {}) => {
        const stringifiedQuery = queryString.stringify(query);
        return api.post(`${CREATE_ORDER}?${stringifiedQuery}`, params);
    };

    const postDsaOneStepAppointment = (params, zone, payload) => {
        if (parseInt(zone && zone.home_inspection_cost)) {
            if (params.token) {
                return api.post(`${DSA_TWO_STEP_APPOINTMENT}?pay=1&src=${params.dsaSource}&token=${params.token}`, payload);
            } else {
                return api.post(`${DSA_TWO_STEP_APPOINTMENT}?oneStep=true&pay=1&src=${params.dsaSource}`, payload);
            }
        } else if (params.token) {
            return api.post(`${DSA_TWO_STEP_APPOINTMENT}?src=${params.dsaSource}&token=${params.token}`, payload);
        } else {
            return api.post(`${DSA_TWO_STEP_APPOINTMENT}?oneStep=true&src=${params.dsaSource}`, payload);
        }
    };

    const updateDsaOneStepAppointment = (params, zone, payload) => {
        if (parseInt(zone && zone.home_inspection_cost)) {
            return api.put(`${DSA_TWO_STEP_APPOINTMENT}?token=${params.token}&pay=1&src=${params.dsaSource}`, payload);
        } else {
            return api.put(`${DSA_TWO_STEP_APPOINTMENT}?token=${params.token}&src=${params.dsaSource}`, payload);
        }
    };

    const createLeadWithoutPhone = (query, params) => {
        return api.post(`${LEAD_WITHOUT_PHONE}?${query}`, params);
    };

    const updateLeadWithoutPhone = (query, params) => {
        return api.put(`${LEAD_WITHOUT_PHONE}?${query}`, params);
    };

    const getPostInspectionCarDetails = (appointmentId, isSellonline = true) => {
        const stringifiedQuery = queryString.stringify({
            ...(isSellonline && {
                sellOnline: true
            })
        });
        return api.get(`/appointment/${appointmentId}?${stringifiedQuery}`);
    };
    const confirmCancelAppoiment = (token, params) => {
        return api.put(`${CANCEL_APPOINTMENT}/${token}`, params);
    };

    const fetchBannerWidget = (params) => {
        const config = {
            headers: {
                ...(params.token && { "Token": params.token }),
                ...(params.pvtAuth && { "pvtAuthorization": params.pvtAuth }),
                ...(params.deviceCategory && { "device_category": params.deviceCategory })
            },
            ...(params.customError && { "customError": params.customError })
        };
        return api.get(`${params.version ? `/${params.version}${BANNER_WIDGET}` : BANNER_WIDGET}`, config);
    };

    const updateLeadWithGsDeny = (token, params) => {
        return api.put(`${GS_DENY}/${token}`, params);
    };

    const getSellerReview = (appointmentId) => {
        return api.get(`${GET_SELL_ONLINE_OFFER_PRICE}?appointmentId=${appointmentId}`);
    };
    const requestCallback = (token) => {
        return api.post(`${REQUEST_CALLBACK_APPOINTMENT}/${token}`);
    };

    const getQuestionForUserIntent = () => {
        return api.get(`${GET_QUESTION_FOR_USER_INTENT}`);
    };

    const whatsappConfirmation = (token, payload) => {
        return api.put(`${WHATSAPP_CONFIRMATION}/${token}`, payload);
    };

    const getAppointmentDetailsByToken = (token, pvtToken) => {
        return api.get(`v2${APPOINTMENT_DETAILS_BY_TOKEN}/${token}`, { parse: true,
            headers: {
                pvtAuthorization: pvtToken
            }
        }

        );
    };

    const getAppointmentDetailsById = (id) => {
        return api.get(`${APPOINTMENT_DETAILS_BY_ID}/${id}`, { parse: true });
    };

    const sendOTP = (params) => {
        return api.get(OTP_SEND, { params });
    };

    const verifyOTP = (params) => {
        return api.get(`${OTP_VERIFY}?${queryString.stringify(params)}`);
    };

    const rescheduleAppointment = (token, params) => {
        return api.put(`${APPOINTMENT_RESCHEDULE}/${token}`, params);
    };

    const checkAppointmentExistence = ({ mobile, modelId, vehicleRegNo = "", sourceQuery = "", deviceCategory = "m-web", gaClientId = "" }) => {
        const config = {
            headers: {
                device_category: deviceCategory
            }
        };
        const payload = {
            modelId,
            phone: mobile,
            deviceCategory,
            ...(vehicleRegNo && {vehicleRegNo}),
            ...(sourceQuery && { query_params: sourceQuery }),
            ...(gaClientId && { gaClientId })
        };
        return api.post(DUPLICATE_APPOINTMENT, payload, config);
    };

    const reVerifyAppointment = params => {
        return api.post(`${RE_VERIFY_APPOINTMENT}?token=${params.token}`, null, {
            headers: {
                ...(params.pvtAuth && { "Pvt-Authorization": params.pvtAuth })
            }
        });
    };

    const verifyAuthUserLead = params => {
        let verifyUrl = `${VERIFY_AUTH_USER_LEAD}?token=${params.token}`;
        if (params.source) {
            verifyUrl = `${verifyUrl}&source=${params.source}`;
        }
        return api.post(verifyUrl, null, {
            headers: {
                ...(params.pvtAuth && { "Pvt-Authorization": params.pvtAuth })
            }
        });
    };

    const getMyAppointments = ({ userId }) => {
        return api.get(`${MY_APPOINTMENTS}?userid=${userId}`);
    };

    const saveUserEmail = params => {
        return api.post(SAVE_USER_EMAIL, params);
    };

    const updateLeadDetails = (params) => {
        return api.put(`${UPDATE_LEAD_DETAIL}`, params);
    };

    const createRtoLead = (payload) => {
        return api.post(RTO_LEAD, payload);
    };

    const updateRtoLead = (token, params) => {
        return api.put(`${RTO_LEAD}/${token}`, params);
    };

    const getRtoLeadDetails = (token) => {
        return api.get(`${RTO_LEAD}/${token}`);
    };

    const scheduleConsultation = (payload) => {
        return api.post(SCHEDULE_CONSULTATION, payload);
    };

    const checkInspectedAppointmentExistence = (regNo, leadSource, isDesktop = false) => {
        const config = {
            headers: {
                device_category: isDesktop ? "web" : "m-web"
            }
        };
        return api.get(`${DUPLICATE_INSPECTED_APPOINTMENT}/${regNo}?leadType=${leadSource}`, config);
    };

    const checkLeadPhoneNumber = ({ token, phone, vasType = "" }) => {
        return api.get(`/${vasType ? `${vasType}/` : ""}${token}/phone-changed/${phone}`);
    };

    const sendOtpViaLeadToken = (token, sourceChannel = "") => {
        return api.get(`/pvt/${token}/send-otp${sourceChannel ? `?sourceChannel=${sourceChannel}` : ""}`);
    };

    const verifyOtpViaLeadToken = ({ token, otp, sourceChannel = "" }, query = "") => {
        const queryStr = typeof query === "string" ? query : "";
        const queryParams = queryStr ? queryStr : `${sourceChannel ? `?sourceChannel=${sourceChannel}` : ""}`;
        return api.post(`/pvt/${token}/verify-otp/${otp}${queryParams}`);
    };

    const createProspectLead = (payload, authToken) => {
        return api.post(PROSPECT_LEAD, payload, {
            headers: {
                "pvtAuthorization": `Bearer ${authToken}`
            }
        });
    };

    const getProspectLeadDetails = (token, challanOtpEligblityCheckRequired = false) => {
        return api.get(`${PROSPECT_LEAD}/${token}`, {
            params: {
                ...(challanOtpEligblityCheckRequired && { challanOtpEligblityCheckRequired })
            }
        });
    };

    const getChallanList = ({ token, authToken, isDesktop = false }) => {
        return api.get(`${CHALLAN_LIST}/${token}`, {
            headers: {
                pvtAuthorization: `Bearer ${authToken}`,
                origin_source: "c2b-website",
                device_category: isDesktop ? "web" : "m-web",
                platform: "Challan"
            }
        });
    };

    const updateProspectLead = (payload) => {
        return api.put(PROSPECT_LEAD, payload);
    };

    const getCarServiceHistory = ({ token, authToken }) => {
        return api.get(`${PROSPECT_LEAD}${CAR_SERVICE_HISTORY}/${token}`, {
            headers: {
                "pvtAuthorization": `Bearer ${authToken}`
            }
        });
    };

    const getRepeatInspectionDetails = (token, isDesktop = false) => {
        const config = {
            headers: {
                device_category: isDesktop ? "web" : "m-web"
            }
        };
        return api.get(`${REPEAT_INSPECTION}/${token}`, config);
    };

    const updateScrapCarConsent = (token) => {
        return api.post(`${SCRAP_CAR}/${token}/intent?customerConsent=yes`);
    };

    const saveScrapCarQuestion = (payload) => {
        return api.post(SAVE_SCRAP_CAR_QUESTION, payload);
    };

    const updateGeoDetailsInLead = (params, token) => {
        return api.put(`/lead/${token}/geo-data`, params);
    };

    const saveCcDropLead = (payload) => {
        return api.post(CC_DROP_LEAD, payload);
    };

    const getCarWashSlots = (token) => {
        return api.get(`${CAR_WASH_SLOTS}/${token}`);
    };

    const bookCarWash = ({ payload, isDesktop }) => {
        const config = {
            headers: {
                ...(payload.authToken && { "pvtAuthorization": payload.authToken }),
                source: isDesktop ? "web" : "m-web"
            }
        };
        return api.post(`${BOOK_CAR_WASH}/${payload.token}`, payload.payload, config);
    };

    const cancelCarWash = (params) => {
        const config = {
            headers: {
                ...(params.authToken && { "pvtAuthorization": params.authToken })
            }
        };
        return api.post(`${CANCEL_CAR_WASH}/${params.token}`, {}, config);
    };

    const getChannelPartnerLeadDetails = (token) => {
        return api.get(`${CHANNEL_PARTNER_LEAD}/${token}/lead`);
    };

    const updatePreInspectionDocumentStatus = (params) => {
        return api.put(MARK_DOC_NOT_UPLOADD, params);
    };

    const submitBuyerIntent = (params) => {
        return api.put(`/buyer/${params.token}/intent?ansId=${params.ansId}`);
    };

    const createPDILead = (params) => {
        const config = {
            headers: {
                ...(params.secureToken && { pvtAuthorization: params.secureToken })
            }
        };
        return api.post(`${INSPECTION_LEAD}`, params.payload, config);
    };

    const createOrderPrePurchase = ({ payload, isDesktop }) => {
        const config = {
            headers: {
                ...(payload.authToken && { "pvtAuthorization": payload.authToken }),
                source: isDesktop ? "web" : "m-web"
            }
        };
        return api.post(`${PRE_DELIVERY_INSPECTION_ORDER}/${payload.token}${payload.regNum ? `?regNum=${payload.regNum}` : ""}`, {
            queryParams: payload.queryParams
        }, config);
    };

    const getPreDeliveryInspectionPrice = ({ newCarsPdiLead = false }) => {
        return api.get(`${PRE_DELIVERY_INSPECTION_PRICE}${newCarsPdiLead ? "?isNewCarsPdi=true" : ""}`);
    };

    const submitInterest = (payload) => {
        return api.post(FRANCHISE, payload);
    };

    const checkUserInterest = (token) => {
        const config = {
            headers: {
                pvtAuthorization: token
            }
        };
        return api.get(FRANCHISE, config);
    };
    const getCarHealthPrice = (token) => {
        return api.get(`${PRODUCT_CAR_HEALTH}/${token}`);
    };

    const getCarInspectionDetails = (token) => {
        return api.get(`${CHR_LEAD_DETAILS}/${token}`);
    };

    const carHealthCreateOrder = ({ payload, isDesktop }) => {
        const config = {
            headers: {
                ...(payload.authToken && { pvtAuthorization: payload.authToken }),
                source: isDesktop ? "web" : "m-web"
            }
        };
        return api.post(`${CAR_HEALTH_CREATE_ORDER}/${payload.token}?gaId=${payload.gaId || ""}`, {
            queryParams: payload.queryParams
        }, config);
    };

    const getAppClickEvent = ({ token, payload = {}, secureToken }) => {
        const config = {
            headers: {
                "Pvt-Authorization": secureToken
            }
        };
        return api.post(`${CAMPAIGN_TOKEN}/${token}`, payload, config);
    };

    const getAppointmentByOrder = (params) => {
        const config = {
            headers: {
                ...(params.authToken && { pvtAuthorization: params.authToken })
            }
        };
        return api.get(`/appointment/${params.orderNumber}/${params.orderItemId}`, config);
    };

    const updatePDILead = (params) => {
        const config = {
            headers: {
                ...(params.secureToken && { pvtAuthorization: params.secureToken })
            }
        };
        return api.put(`${INSPECTION_LEAD}/${params.token}`, params.payload, config);
    };

    const saveDataToLeadAttributes = (token, payload) => {
        return api.put(`${LEAD_ATTRIBUTES}/${token}`, payload);
    };

    const saveLeadFormData = (payload) => {
        return api.post("/client/lead-form", payload);
    };

    const saveUserAttributionData = (secureToken, data) => {
        const config = {
            headers: {
                pvtAuthorization: secureToken
            }
        };
        return api.post(`/repeat/inspection`, data, config);
    };

    const sendStateChallanOtp = ({ leadToken, secureToken }) => {
        return api.get(`${STATE_CHALLAN_OTP}/${leadToken}`, {
            headers: { pvtAuthorization: secureToken }
        });
    };

    const verifyStateChallanOtp = ({ leadToken, secureToken, payload }) => {
        return api.post(`${STATE_CHALLAN_OTP}/${leadToken}`, payload, {
            headers: { pvtAuthorization: secureToken }
        });
    };

    const saveRtoQuestion = (payload) => {
        return api.post("/rto/appointment/question", payload);
    };

    const getChallanCount = ({ regNo, authToken, isDesktop = false, platform = "" }) => {
        return api.get(`/challan/count?regNumber=${regNo}`, {
            headers: {
                pvtAuthorization: authToken,
                origin_source: "c2b-website",
                device_category: isDesktop ? "web" : "m-web",
                platform
            }
        });
    };

    const saveLeadMeta = (payload) => {
        return api.post("/lead-meta/save", payload);
    };

    const getPdiPriceDetails = () => {
        return api.get(PRE_DELIVERY_INSPECTION_PRICE_ALL);
    };

    const getLeadFormData = (clientId) => {
        return api.get(`/client/lead-form?clientId=${clientId}`);
    };

    const saveOnlineQuestion = (payload) => {
        return api.post(`appointment/question/save`, payload);
    };
    const sendChallanVerificationOtp = (token, payload) => {
        return api.post(`/api/v1/prospect-lead/rto-phone/otp/send`, payload, {
            headers: {
                pvtAuthorization: token
            }
        });
    };

    const verifyChallanVerificationOtp = (token, payload) => {
        return api.post(`/api/v1/prospect-lead/rto-phone/otp/verify`, payload, {
            headers: {
                pvtAuthorization: token
            }
        });
    };

    const fetchLeadDetailsProtected = (token, params = {}, isMsite = false, pvtToken) => {
        return api.get(`v2${LEAD_DETAILS}/${token}`, {
            params,
            parse: true,
            headers: {
                "deviceCategory": isMsite ? "m-web" : "web",
                pvtAuthorization: pvtToken
            }
        });
    };

    const getAppointmentDetailsByTokenProtected = (token, pvtToken) => {
        return api.get(`v2${APPOINTMENT_DETAILS_BY_TOKEN}/${token}`, {
            parse: true,
            headers: {
                pvtAuthorization: pvtToken
            }
        });
    };
    const getAppointmentDetailsByTokenv1 = (token, pvtToken) => {
        return api.get(`${APPOINTMENT_DETAILS_BY_TOKEN}/${token}`, {
            parse: true,
            headers: {
                pvtAuthorization: pvtToken
            }
        });
    };
    const getSimilarCars = (token) => {
        return api.get(`/similar-cars?token=${token}`);
    };

    const postSharePrice = (token, payload) => {
        return api.post(`/${token}/expected-price`, payload);
    };

    const sendMandateOtp = (token, payload) => {
        return api.post(`api/v2/prospect-lead/parivahan/otp/send`, payload, {
            headers: {
                pvtAuthorization: token
            }
        });
    };

    const verifyMandateOtp = (token, payload, prospectLeadToken) => {
        return api.post(`api/v2/prospect-lead/parivahan/otp/verify/${prospectLeadToken}`, payload, {
            headers: {
                pvtAuthorization: token
            }
        });
    };

    return {
        sendOTP,
        verifyOTP,
        saveUserEmail,
        requestCallback,
        postc24QFeedback,
        postOfferPriceInput,
        postUserChoice,
        postSellOnlineFeedback,
        sellOnlineCallBack,
        createLead,
        updateLead,
        fetchLeadDetails,
        postSellOnlineQuestion,
        getSellOnlinePrice,
        getDsaDetail,
        createAppointment,
        updateAppointment,
        getQuestionForUserIntent,
        postDsaTwoStepAppointment,
        postDsaOneStepAppointment,
        updateDsaTwoStepAppointment,
        createLeadWithoutPhone,
        updateLeadWithoutPhone,
        updateDsaOneStepAppointment,
        getPostInspectionCarDetails,
        createOneStepAppointment,
        submitOrderRequest,
        getAppointmentDetailsByToken,
        getAppointmentDetailsById,
        confirmCancelAppoiment,
        fetchBannerWidget,
        updateLeadWithGsDeny,
        getSellerReview,
        rescheduleAppointment,
        whatsappConfirmation,
        reVerifyAppointment,
        checkAppointmentExistence,
        verifyAuthUserLead,
        getMyAppointments,
        updateLeadDetails,
        createRtoLead,
        updateRtoLead,
        getRtoLeadDetails,
        scheduleConsultation,
        checkInspectedAppointmentExistence,
        checkLeadPhoneNumber,
        sendOtpViaLeadToken,
        verifyOtpViaLeadToken,
        createProspectLead,
        getProspectLeadDetails,
        getChallanList,
        updateProspectLead,
        getCarServiceHistory,
        getRepeatInspectionDetails,
        updateScrapCarConsent,
        saveScrapCarQuestion,
        updateGeoDetailsInLead,
        saveCcDropLead,
        getCarWashSlots,
        bookCarWash,
        getChannelPartnerLeadDetails,
        submitBuyerIntent,
        updatePreInspectionDocumentStatus,
        cancelCarWash,
        createPDILead,
        createOrderPrePurchase,
        submitInterest,
        checkUserInterest,
        getCarHealthPrice,
        getCarInspectionDetails,
        carHealthCreateOrder,
        getAppClickEvent,
        getPreDeliveryInspectionPrice,
        getAppointmentByOrder,
        updatePDILead,
        saveDataToLeadAttributes,
        saveLeadFormData,
        saveUserAttributionData,
        sendStateChallanOtp,
        verifyStateChallanOtp,
        saveRtoQuestion,
        getChallanCount,
        saveLeadMeta,
        getPdiPriceDetails,
        getLeadFormData,
        saveOnlineQuestion,
        sendChallanVerificationOtp,
        verifyChallanVerificationOtp,
        fetchLeadDetailsProtected,
        getAppointmentDetailsByTokenProtected,
        getSimilarCars,
        postSharePrice,
        sendMandateOtp,
        verifyMandateOtp,
        fetchLeadDetailsv1,
        getAppointmentDetailsByTokenv1
    };
};

export const LeadServiceJava = leadServiceJavaApi(middlewareConfig(MIDDLEWARE_ENUMS.LEAD_SERVICE_JAVA_BASE_CONST));
