/* eslint-disable max-params */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NUMBER } from "../../../constants/app-constants";
import { sendOTP, verifyOtp, login, logout, getUserDetails } from "./actions";
import { setMobile, getProfileDetails, markUnauthorized, saveUserCibilConsent} from "../../configuration/actions";
import { sendCustomGaEvents } from "../../../tracking";
import { LOGIN_PAGE } from "../../../constants/login-constants";

// eslint-disable-next-line max-params, no-unused-vars
export default function withOtp(Component, ignoredStates = [], disableLoadingOnVerification = true, options = {}) {
    const { isCustomLogin = false } = options || {};

    const OtpComponent = (props) => {

        const {
            postInspectionDiy,
            isLoggedIn,
            mobile,
            loginConnect,
            sendOTPConnect,
            setMobileConnect,
            verifyOtpConnect,
            getProfileDetailsConnect,
            logoutConnect,
            markUnauthorizedConnect,
            saveUserCibilConsentConnect = () => {},
            currentPageType = "",
            onSuccess = () => {}
        } = props;

        const { carDetails } = postInspectionDiy || {};
        const { phone } = carDetails || {};
        const [otp, setOtp] = useState("");
        const [showOtpField, setShowOtpField] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [errorMessage, setErrorMessage] = useState("");
        const [isAutoFilledOTP, setIsAutoFilledOTP] = useState(false);

        const onMobileNumberChange = ({ target: { value }}) => {
            setMobileConnect(value);
        };

        const resetErrorMessage = () => {
            setErrorMessage("");
        };

        const logoutUser = (logoutOptions) => {
            const {logoutCallback = () => {}} = logoutOptions || {};
            logoutConnect().then(() => {
                markUnauthorizedConnect();
                logoutCallback();
            });
        };

        const sendOtp = (number) => {
            setOtp("");
            resetErrorMessage();
            sendOTPConnect(number, true)
                .then(() => {
                    setShowOtpField(true);
                    setIsLoading(false);
                })
                .catch(() => {
                    setShowOtpField(false);
                    setIsLoading(false);
                });
        };

        const isLoginRequired = () => {
            return !isLoggedIn;
        };

        const getOtpHandler = (mobileNumber = "") => {
            setIsLoading(true);
            sendOtp(mobileNumber || mobile);
            setIsAutoFilledOTP(true);
        };

        const fetchProfileDetails = (resolve, overrideCustomLogin = false) => {
            if (isCustomLogin && !overrideCustomLogin) {
                resolve();
            } else {
                getProfileDetailsConnect().then(async (profileResponse) => {
                    const { data: { data: { user } } } = profileResponse;
                    const { user_id: userId = "", token: secureToken} = user || {};
                    await saveUserCibilConsentConnect({ userId, source: "mSite", secureToken }).catch(() => "");
                    if (disableLoadingOnVerification) setIsLoading(false);
                    resolve(user);
                }).catch(() => {
                    resolve();
                    if (disableLoadingOnVerification) setIsLoading(false);
                });
            }
        };

        const handleUserLogin = (mobileNumber = "", otpVerificationFn = verifyOtpConnect, extraParams = {}) => {
            resetErrorMessage();
            setIsLoading(true);
            const params = {
                phone_number: mobileNumber || mobile,
                otp,
                ...extraParams
            };
            return new Promise((resolve, reject) => {
                otpVerificationFn(params, true).then(async (response) => {
                    if (response.error) {
                        const { error: { error_message: errormessage } } = response;

                        window.setTimeout(() => {
                            setOtp("");
                            setErrorMessage(errormessage);
                            setIsLoading(false);
                        }, NUMBER.FOUR_HUNDRED);
                    } else {
                        onSuccess(true);
                        const responseData = response.data || {};
                        const loginUrl = ((response.data || {}).login_url || "");
                        const accessToken = loginUrl.split("access-token=")[1] || responseData["access-token"];
                        const payload = {
                            "access-token": accessToken,
                            redirect_url: encodeURIComponent(window.location.href)
                        };
                        // const loginUser =  (response.data || {}).user;
                        // const { user_id: loginUserId } = loginUser || {};

                        sendCustomGaEvents({
                            event: "login_done",
                            eventAction: "login_done",
                            eventLabel: LOGIN_PAGE[currentPageType] || currentPageType
                        });

                        loginConnect(payload).then(() => { fetchProfileDetails(resolve); }).catch(() => { fetchProfileDetails(resolve); });
                    }

                }).catch(error => {
                    const errorCode = (error &&
                        error.errors &&
                        error.errors[0] &&
                        error.errors[0].error) || (error && error.message) || "";
                    setErrorMessage(errorCode);
                    reject(error);
                    setOtp("");
                    setIsLoading(false);
                });
            });
        };

        const onOtpChange = (number) => {
            if (number && number.length === NUMBER.ONE) resetErrorMessage();
            setOtp(number);
        };

        const resendOTPHandler = () => {
            sendOtp(mobile);
        };

        return (
            <Component
                {...props}
                otp={otp}
                phoneNumber={mobile || phone}
                showOtpField={showOtpField}
                isLoading={isLoading}
                errorMessage={errorMessage}
                isAutoFilledOTP={isAutoFilledOTP}
                onMobileNumberChange={onMobileNumberChange}
                isLoginRequired={isLoginRequired}
                getOtpHandler={getOtpHandler}
                onOtpVerify={handleUserLogin}
                onOtpChange={onOtpChange}
                resendOTPHandler={resendOTPHandler}
                setShowOtpField={setShowOtpField}
                setOtp={setOtp}
                setIsAutoFilledOTP={setIsAutoFilledOTP}
                logoutUser={logoutUser}
                setIsLoading={setIsLoading}
                fetchProfileDetails={fetchProfileDetails}
                resetErrorMessage={resetErrorMessage}
                getProfileDetailsConnect={getProfileDetailsConnect}
                setMobileConnect={setMobileConnect}
                verifyOtpConnect={verifyOtpConnect}
            />
        );
    };

    OtpComponent.propTypes = {
        isLoggedIn: PropTypes.bool,
        mobile: PropTypes.string,
        postInspectionDiy: PropTypes.object,
        loginConnect: PropTypes.func,
        setMobileConnect: PropTypes.func,
        verifyOtpConnect: PropTypes.func,
        sendOTPConnect: PropTypes.func,
        getProfileDetailsConnect: PropTypes.func,
        logoutConnect: PropTypes.func,
        markUnauthorizedConnect: PropTypes.func,
        saveUserCibilConsentConnect: PropTypes.func,
        currentPageType: PropTypes.string,
        onSuccess: PropTypes.func
    };

    const mapStateToProps = ({
        user: { isLoggedIn, mobile, currentPageType },
        postInspectionDiy
    }) => {
        return {
            isLoggedIn,
            mobile,
            postInspectionDiy,
            currentPageType
        };
    };

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        loginConnect: login,
        setMobileConnect: setMobile,
        verifyOtpConnect: verifyOtp,
        sendOTPConnect: sendOTP,
        getProfileDetailsConnect: getProfileDetails,
        logoutConnect: logout,
        markUnauthorizedConnect: markUnauthorized,
        saveUserCibilConsentConnect: saveUserCibilConsent,
        getUserDetailsConnect: getUserDetails
    }, dispatch);
    return connect(mapStateToProps, mapDispatchToProps)(OtpComponent);
}
