/* eslint-disable max-params */
import { appUrl } from "../../constants/url-constants";
import { getOtherAnchorURL } from "./get-sell-anchor-url";
import { newCarLinks } from "../../constants/new-cars-constants";

const VIEW_TYPE = {
    GRID: "GRID",
    LIST: "LIST"
};

const CATEGORY_TYPE = {
    POPULAR_CITY: "POPULAR_CITY",
    POPULAR_BRANDS: "POPULAR_BRANDS"
};

export class HeaderParserManager {
    execute(command, ...args) {
        return command.execute(...args);
    }
}
class Command {
    constructor(execute) {
        this.execute = execute;
    }
}

// eslint-disable-next-line func-style
export function parsedLinkConfig(links) {
    const newParsedLinks = links.map((link) => {
        const { description = "", title = "" } = link || {};
        const desiredTitle = description !== "" ? description : title;
        return { ...link, title: desiredTitle };
    });
    // eslint-disable-next-line no-undef
    return newParsedLinks;
}

export const getAllSubOptionsConfig = (seoLinks) => {
    const optionResults = {};
    const leftPanelOptions = seoLinks
        .filter(currentLink => currentLink?.links?.length > 0)
        .map(currentLink => {
            const {
                showLinkTitle = true,
                heading,
                viewType,
                // eslint-disable-next-line no-use-before-define
                links
            } = currentLink;

            optionResults[heading] = {
                viewType,
                links: viewType === VIEW_TYPE?.LIST ? parsedLinkConfig(links) : links,
                showLinkTitle
            };
            return {title: heading};
        });

    return { leftPanelOptions, optionResults };
};

// eslint-disable-next-line func-style
export function modifySeoUrl(urlPath) {
    return `${appUrl()}/${urlPath}`;
}

// eslint-disable-next-line func-style
export function DesktopBuyCarParser({buyCarsHeaderData, currentSelectedLocation, handleBuyCarOnlineClick, changeLocation, isOpen}) {
    return new Command(() => {
        const parsedBuyCarsData = {
            heading: "Buy used car",
            dropdownOptions: {}
        };

        const { data = [] } = buyCarsHeaderData || {};
        const bottomRightUtilityButtons = [
            {
                title: "Buy cars online",
                onClick: handleBuyCarOnlineClick,
                isSuffixIcon: true,
                asLink: true,
                href: `${appUrl()}/buy-used-cars/`
            }
        ];

        const seoLinks = data
            .map(current => {
                const { category = "", links: linkEntity = [], viewType = "" } = current || {};
                const modifiedResponseObject = {
                    links: linkEntity.map(currentLinkEntity => {
                        const { url = "", property = {} } = currentLinkEntity || {};
                        const { imageUrl = "" } = property || {};

                        return {
                            ...currentLinkEntity,
                            imageUrl: isOpen ? imageUrl : "",
                            url: modifySeoUrl(url),
                            property: undefined
                        };
                    })
                };

                return viewType === VIEW_TYPE.GRID && category === CATEGORY_TYPE?.POPULAR_BRANDS
                    ? { ...current, ...modifiedResponseObject, showLinkTitle: false }
                    : { ...current, ...modifiedResponseObject };
            });
        const { leftPanelOptions, optionResults } = getAllSubOptionsConfig(seoLinks);

        parsedBuyCarsData.dropdownOptions = {
            header: {
                title: currentSelectedLocation ? `Buy Used Cars in ${currentSelectedLocation}` : null,
                suffixButton: {
                    title: "Change city",
                    onClick: changeLocation,
                    asLink: false
                }
            },
            options: leftPanelOptions,
            resultLinks: optionResults,
            buttons: bottomRightUtilityButtons
        };
        return parsedBuyCarsData;
    });
}

// eslint-disable-next-line func-style
export function DesktopSellCarParser(sellCarData) {
    return new Command(() => {
        const usedCarValuationLink = {
            title: "Used car valuation",
            url: `${getOtherAnchorURL("used-car-valuation")}`
        };
        const parseSellCarData = {
            heading: "Sell car",
            navLinks: [
                usedCarValuationLink,
                ...sellCarData.map(({ title = "", seoLink = "" } = {}) => {
                    const modifiedTitle = `Sell car in ${title}`;
                    const modifiedSeoLink = modifySeoUrl(seoLink);
                    return { title: modifiedTitle, url: modifiedSeoLink };
                })
            ]
        };
        return parseSellCarData;
    });
}

// eslint-disable-next-line func-style
export function DesktopCFSeoLinkParse(cfData) {
    return new Command(() => {
        const parsedCFData = {
            heading: "Car finance",
            navLinks: cfData.map(({ text = "", url = "" } = {}) => ({
                title: text,
                url
            }))
        };
        return parsedCFData;
    });
}

// eslint-disable-next-line func-style
export function DesktopCarServicesParser(carServicesData) {
    return new Command(() => {
        const parsedCarServicesData = {
            heading: "Car services",
            navLinks: carServicesData.map(({ title = "", seoLink = "", customUrl = "" }) => {
                const modifiedSeoLink = customUrl ? customUrl : modifySeoUrl(seoLink);
                return { title, url: modifiedSeoLink };
            })
        };
        return parsedCarServicesData;
    });
}

const newCarsParentLinks = [
    {
        title: "Explore new car",
        url: modifySeoUrl("new-cars/")
    },
    {
        title: " Compare",
        url: modifySeoUrl("new-cars/compare-cars/")
    }
];

// eslint-disable-next-line func-style
export function DesktopNewCarsParser({isOpen}) {
    return new Command(() => {
        const parsedNewCarsData = {
            heading: "New Cars",
            dropdownOptions: {
                links: newCarsParentLinks.map(currentParentLink => currentParentLink)
            }
        };
        const parserNewCarLink = newCarLinks.reduce((accumulator, currentObject) => {
            const { links: getLinkAssets } = currentObject || {};
            accumulator.push({...currentObject, links: getLinkAssets(isOpen)});
            return accumulator;
        }, []);
        const { leftPanelOptions, optionResults } = getAllSubOptionsConfig(parserNewCarLink);
        parsedNewCarsData.dropdownOptions.options = leftPanelOptions;
        parsedNewCarsData.dropdownOptions.resultLinks = optionResults;
        return parsedNewCarsData;
    });
}

// eslint-disable-next-line func-style
export function MobileBuyCarParser({buyCarsData, location, handleBuyCarOnlineClick, isOpen}) {
    return new Command(() => {
        const { data = [] } = buyCarsData || {};
        const parsedBuyCarData = {
            header: {
                subHeading: `Used cars in ${location}`,
                heading: "Buy used car",
                color: "primary"
            },
            viewType: VIEW_TYPE?.LIST,
            button: {
                title: "Buy Car Online",
                onClick: handleBuyCarOnlineClick
            },
            links: data.map(currentData => {
                const { heading = "", viewType, links: linkEntity } = currentData || {};

                const modifiedLinkEntity = linkEntity.map(currentLink => {
                    const { description = "", property = {}, url = "" } = currentLink || {};
                    const { imageUrl = "" } = property || {};

                    return viewType === VIEW_TYPE?.GRID
                        ? { ...currentLink, url: modifySeoUrl(url), imageUrl: isOpen && imageUrl, property: undefined }
                        : { ...currentLink,  url: modifySeoUrl(url), title: description, imageUrl: isOpen && imageUrl, property: undefined };
                });
                if (heading === "View all cities" && data.length > 1) {
                    return {
                        ...currentData,
                        header: { heading },
                        links: modifiedLinkEntity
                    };
                } else if (viewType === VIEW_TYPE?.GRID && heading === "Browse by Make") {
                    return {
                        ...currentData,
                        header: { heading },
                        showLinkTitle: false,
                        links: modifiedLinkEntity
                    };
                } else {
                    return {
                        ...currentData,
                        header: { heading },
                        links: modifiedLinkEntity
                    };
                }
            })
        };
        return parsedBuyCarData;
    });
}

// eslint-disable-next-line func-style
export function MobileSellCarParser(sellCarData, handleSellCarOnlineClick) {
    return new Command(() => {
        const usedCarValuationLink = {
            title: "Used car valuation",
            url: `${getOtherAnchorURL("used-car-valuation")}`
        };
        const parseSellCarData = {
            header: {
                heading: "Sell car"
            },
            viewType: VIEW_TYPE?.LIST,
            links: [
                usedCarValuationLink,
                ...sellCarData.map(current => {
                    const { title = "", seoLink = "" } = current || {};
                    const modifiedTitle = `Sell car in ${title}`;
                    const modifiedSeoLink = modifySeoUrl(seoLink);
                    return { ...current, title: modifiedTitle, url: modifiedSeoLink };
                })
            ],
            button: {
                title: "Sell car online",
                onClick: handleSellCarOnlineClick
            }
        };
        return parseSellCarData;
    });
}

// eslint-disable-next-line func-style
export function MobileCFSeoLinkParse(cfData) {
    return new Command(() => {
        const parsedCFData = {
            header: {
                heading: "Car finance"
            },
            links: cfData.map(current => {
                const { text = "", url = "" } = current || {};
                return { ...current, title: text, url };
            }),
            viewType: VIEW_TYPE?.LIST
        };
        return parsedCFData;
    });
}

// eslint-disable-next-line func-style
export function MobileNewCarsParser(isOpen) {
    return new Command(() => {
        const parsedNewCarsData = {
            header: {
                heading: "New car"
            },
            links: [
                ...newCarsParentLinks.map(({ title = "", url = "" }) => ({ title, url })),
                ...newCarLinks.map(currentLink => {
                    const { heading = "", links: getLinkAssets } = currentLink || {};
                    return { ...currentLink, header: { heading }, links: getLinkAssets(isOpen) };
                })
            ],
            viewType: VIEW_TYPE?.LIST
        };
        return parsedNewCarsData;
    });
}

// eslint-disable-next-line func-style
export function MobileCarServicesParser(carServicesData) {
    return new Command(() => {
        const parsedCarServicesData = {
            header: {
                heading: "Car services"
            },
            links: carServicesData.map(({ title = "", seoLink = "", customUrl = "" }) => {
                const modifiedSeoLink = customUrl ? customUrl : modifySeoUrl(seoLink);
                return { title, url: modifiedSeoLink };
            }),
            viewType: VIEW_TYPE?.LIST
        };
        return parsedCarServicesData;
    });
}

